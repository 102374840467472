import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WalletDialogService } from '@x/ecommerce-admin/app/wallets/services/wallet-dialog.service';
import {
  CartService,
  IOrderDetailObject,
  IUserPaymentMethodObject,
  OrderService,
  PaymentMethodRowObject,
  PaymentMethodService,
  PaymentService,
  UserPaymentMethodsService,
  WalletObject,
  WalletService,
} from '@x/ecommerce/domain-client';
import { UserPaymentMethodDatasource } from '@x/ecommerce/domain-data';
import { OrderState, PaymentState } from '@x/schemas/ecommerce';
import { QueryRef } from 'apollo-angular';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PaymentProviderDialogService } from '../../services/payment-provider-dialog.service';

export interface OrderPaymentDialogData {
  orderId: number;
}

export interface PaymentMethodViewModel {
  method: PaymentMethodRowObject;
  userMethods: IUserPaymentMethodObject[];
}

@Component({
  selector: 'x-order-payment-dialog',
  templateUrl: './order-payment-dialog.component.html',
  providers: [UserPaymentMethodDatasource],
})
export class OrderPaymentDialogComponent implements OnInit, OnDestroy {
  selectedMethodIndex: number = -1;
  private _destroy$ = new Subject<void>();

  paymentMethods$ = new BehaviorSubject<PaymentMethodViewModel[]>([]);
  order$ = new BehaviorSubject<IOrderDetailObject | undefined>(undefined);
  wallets$ = new BehaviorSubject<WalletObject[] | undefined>(undefined);

  polling$ = new BehaviorSubject<boolean>(false);
  loading$ = new BehaviorSubject<boolean>(false);

  // TODO: remove anys
  watchRef: QueryRef<any, any>;
  providerDialogRef: MatDialogRef<any, any> | undefined;

  save = true;

  constructor(
    private dialog: MatDialogRef<OrderPaymentDialogComponent, OrderPaymentDialogData>,
    @Inject(MAT_DIALOG_DATA) private dialogData: OrderPaymentDialogData,
    private orderService: OrderService,
    private cartService: CartService,
    private paymentService: PaymentService,
    private paymentMethodsService: PaymentMethodService,
    private walletService: WalletService,
    private walletDialogService: WalletDialogService,
    private userPaymentMethodService: UserPaymentMethodsService,
    private paymentProviderDialogs: PaymentProviderDialogService,
    private snackbar: MatSnackBar,
  ) {}

  async ngOnInit(): Promise<void> {
    this.order$.pipe(takeUntil(this._destroy$)).subscribe(async (order) => {
      if (order) {
        await this.fetchPaymentMethods(order);
        await this.fetchWallets(order);
      }
    });
    await this.fetchOrder(this.dialogData.orderId);
  }

  async ngOnDestroy(): Promise<void> {
    this.stopPolling();
    this._destroy$.next();
    this._destroy$.complete();
  }

  async fetchOrder(orderId: number) {
    this.order$.next(await this.orderService.fetchById(orderId).toPromise());
  }

  async fetchPaymentMethods(order: IOrderDetailObject) {
    const paymentMethods = await firstValueFrom(
      this.paymentMethodsService.fetchForChannels([order.channel.id]),
    );

    if (!order.user) throw new Error(); // TODO: handle
    const userPaymentMethods = await firstValueFrom(
      this.userPaymentMethodService.fetchItems({ filter: { userIds: [order.user.id] } }),
    );
    this.paymentMethods$.next(
      paymentMethods.map((method) => ({
        method,
        userMethods: userPaymentMethods.items.filter(
          (userMethod) => userMethod.method.id === method.id,
        ),
      })),
    );
  }

  async fetchWallets(order: IOrderDetailObject) {
    const wallets = order.user
      ? await this.walletService
          .fetchForUser(order.user.id)
          .pipe(map((wallets) => wallets.filter((w) => w.currency === order.currency)))
          .toPromise()
      : [];
    if (wallets && wallets.length > 0) {
      this.wallets$.next(wallets);
    }
  }

  async requestNewPayment(method: PaymentMethodRowObject) {
    this.loading$.next(true);
    await this.doCheckoutIfRequired();
    this.paymentService
      .requestNewPayment(this.dialogData.orderId, method.id, this.save)
      .pipe(takeUntil(this._destroy$))
      .subscribe((payment) => {
        this.providerDialogRef = this.paymentProviderDialogs.open(payment);
        this.providerDialogRef?.afterClosed().pipe(takeUntil(this._destroy$)).subscribe();
        this.loading$.next(false);
        this.startPolling(payment.id);
      });
  }

  async requestPayment(userMethod: IUserPaymentMethodObject) {
    this.loading$.next(true);
    await this.doCheckoutIfRequired();
    this.paymentService
      .requestPayment(this.dialogData.orderId, userMethod.id)
      .pipe(takeUntil(this._destroy$))
      .subscribe((payment) => {
        this.loading$.next(false);
        this.startPolling(payment.id);
      });
  }

  async requestWalletPayment(wallet: WalletObject) {
    this.walletDialogService
      .openDebitPaymentDialog(wallet.user.id, this.dialogData.orderId)
      .afterClosed()
      .pipe(takeUntil(this._destroy$))
      .subscribe((transaction) => {
        this.loading$.next(false);
        if (transaction) {
          this.closeDialog();
        }
      });
  }

  async doCheckoutIfRequired() {
    const order = this.order$.value;
    if (!order) {
      throw Error();
    }
    if (!order.checkoutRequirements.canCheckout) {
      this.snackbar.open(
        `Cannot checkout (${order.checkoutRequirements.requirements
          .filter((c) => !c.isValid)
          .map((c) => c.message)
          .join(', ')}).`,
        'Ok',
      );
      return;
    }

    if (order.state === OrderState.Cart) {
      await this.cartService
        .checkout({
          orderId: this.dialogData.orderId,
        })
        .toPromise();
    }
  }

  closeDialog() {
    this.dialog.close();
  }

  private startPolling(paymentId: number) {
    this.polling$.next(true);
    this.watchRef = this.paymentService.watchById(paymentId);
    this.watchRef.valueChanges.pipe(takeUntil(this._destroy$)).subscribe((result) => {
      const payment = result.data.payment;
      if (
        payment.state !== PaymentState.New &&
        payment.state !== PaymentState.Processing &&
        payment.state !== PaymentState.Redemption
      ) {
        this.providerDialogRef?.close();
        this.stopPolling();
        this.closeDialog();
      }
    });
    this.watchRef.startPolling(2500);
  }

  private stopPolling() {
    this.polling$.next(false);
    this.watchRef?.stopPolling();
  }
}
