import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface OrderGiftOptionsDialogData {
  isGift?: boolean;
  giftMessage?: string | null;
}

export interface OrderGiftOptionsDialogResult {
  isGift: boolean;
  giftMessage?: string | null;
}

@Component({
  selector: 'x-order-gift-options-dialog',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <h2 mat-dialog-title>Update Gift Options</h2>
    <mat-dialog-content class="mat-typography" [formGroup]="formGroup">
      <x-dashboard-form-layout>
        <x-form-panel>
          <mat-form-field appearance="outline" *ngIf="formGroup.value.isGift">
            <mat-label>Gift Message</mat-label>
            <textarea
              matInput
              formControlName="giftMessage"
              cdkTextareaAutosize
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="20"
            ></textarea>
            <mat-hint>{{ this.giftMessageControl.value?.length }} characters used</mat-hint>
          </mat-form-field>
        </x-form-panel>
      </x-dashboard-form-layout>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
      <mat-slide-toggle [formControl]="isGiftControl">Is Gift Order?</mat-slide-toggle>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="submit()">Update</button>
    </div>
  `,
})
export class OrderGiftOptionsDialogComponent {
  isGiftControl = new UntypedFormControl(this.data.isGift ?? false);
  giftMessageControl = new UntypedFormControl(this.data.giftMessage ?? null, [
    Validators.maxLength(4096),
  ]);

  formGroup = new UntypedFormGroup({
    isGift: this.isGiftControl,
    giftMessage: this.giftMessageControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: OrderGiftOptionsDialogData,
    private dialogRef: MatDialogRef<OrderGiftOptionsDialogComponent, OrderGiftOptionsDialogResult>,
  ) {}

  submit() {
    const value = this.formGroup.value;

    let giftMessage = value.giftMessage ? String(value.giftMessage).trim() : null;
    if (typeof giftMessage === 'string' && giftMessage.length === 0) giftMessage = null;

    this.dialogRef.close({
      isGift: value.isGift ?? false,
      giftMessage: value.isGift ? giftMessage : null,
    });
  }
}
