import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataTableView } from '@x/common/data';
import {
  MapBoundsChangedData,
  MapCenterChangedData,
  MapMarkerItem,
  MapPolygonItem,
  MapZoomChangedData,
} from '@x/common/map';
import { LocalStorage } from '@x/common/storage';
import { AddressDialogService } from '@x/ecommerce-admin/app/core/services/address-dialog.service';
import {
  PointObject,
  formatPolygonForGoogle,
} from '@x/ecommerce-admin/app/geo-region/components/georegion-index/georegion-index.types';
import { ShipmentFormDialogData } from '@x/ecommerce-admin/app/logistics/components/shipment-form-dialog/shipment-form-dialog.component';
import { ShipmentDialogService } from '@x/ecommerce-admin/app/logistics/services/shipment-dialog.service';
import {
  CartService,
  GeoRegionDefinitionObject,
  GeoRegionService,
  IAddressInput,
  OrderMapItem,
  OrderService,
} from '@x/ecommerce/domain-client';
import { AddressAssignment, CoordinatesFilterInput, OrderFilterInput } from '@x/schemas/ecommerce';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  from,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
  toArray,
} from 'rxjs';

const MAP_OPTIONS_STORAGE_KEY = 'order_map_component_view_state';

export type OrderMapViewState = {
  zoom?: number | null;
  center?: {
    lat: number;
    lng: number;
  } | null;
};

@Component({
  selector: 'x-order-map',
  templateUrl: './order-map.component.html',
  host: {
    class: 'x-order-map',
  },
})
export class OrderMapComponent implements OnInit, OnDestroy {
  private _destroy$ = new Subject<void>();

  private _mapBounds$ = new BehaviorSubject<CoordinatesFilterInput | null>(null);
  private _viewBounds$ = new BehaviorSubject<CoordinatesFilterInput | null>(null);

  private init$ = new BehaviorSubject<boolean>(false);

  showGeoRegionsControl = new FormControl<boolean>(false);

  @Input()
  view: DataTableView<OrderMapItem, OrderFilterInput, any, number>;

  @Output()
  boundsChanged = new EventEmitter<CoordinatesFilterInput | null>();

  markers$: Observable<MapMarkerItem<OrderMapItem[]>[]>;
  polygons$: Observable<MapPolygonItem<GeoRegionDefinitionObject>[]>;

  boundsUnsynced$ = combineLatest({
    map: this._mapBounds$,
    view: this._viewBounds$,
  }).pipe(
    distinctUntilChanged(),
    map(({ map, view }) => !Object.is(map, view)),
    startWith(false),
  );

  mapOptions: google.maps.MapOptions;

  constructor(
    private readonly storage: LocalStorage,
    private readonly orderService: OrderService,
    private readonly cartService: CartService,
    private readonly geoRegionService: GeoRegionService,
    private readonly shipmentDialogService: ShipmentDialogService,
    private readonly addressDialogService: AddressDialogService,
    private readonly snackbar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.view
      .filterChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe((filter) => {
        const b = filter?.shippingAddressCoordinateFilter ?? null;
        if (!Object.is(b, this._viewBounds$.value)) {
          this._viewBounds$.next(b);
        }
      });

    const geoRegions$ = this.view.filterChanges().pipe(
      switchMap((filter) =>
        this.geoRegionService
          .fetchDetails({
            channelIds: filter?.channelIds,
          })
          .pipe(
            tap((data) => {}),
            switchMap((regions) =>
              this.geoRegionService.definitions({
                channelIds: filter?.channelIds,
                geoRegionIds: regions.map(({ id }) => id),
              }),
            ),
            map(
              (definitions) =>
                definitions
                  .flatMap((data: GeoRegionDefinitionObject) =>
                    data.boundary
                      ? <MapPolygonItem<GeoRegionDefinitionObject>>{
                          data,
                          polygon: {
                            fillColor: '#004998',
                            strokeColor: '#004998',
                            fillOpacity: 0.1,
                            strokeOpacity: 0.4,
                            strokeWeight: 0.5,
                            paths: formatPolygonForGoogle(data.boundary),
                          },
                        }
                      : undefined,
                  )
                  .filter((d) => !!d) as MapPolygonItem<GeoRegionDefinitionObject>[],
            ),
          ),
      ),
    );

    this.polygons$ = combineLatest({
      active: this.showGeoRegionsControl.valueChanges,
      regions: geoRegions$,
    }).pipe(
      startWith({
        active: true,
        regions: [],
      }),
      map(({ active, regions }) => {
        return active ? regions : [];
      }),
    );

    this.markers$ = this.init$.pipe(
      distinctUntilChanged(),
      filter(Boolean),
      switchMap(() => this.view.connect()),
      switchMap((rows) =>
        from(rows as OrderMapItem[]).pipe(
          filter((row) => !!row.shippingAddress?.coordinates),
          toArray(),
        ),
      ),
      map((rows: OrderMapItem[]) =>
        Object.entries(
          rows.reduce(
            (group, row) => {
              const coords = row.shippingAddress?.coordinates as PointObject;
              const key = `${coords.lat},${coords.lng}`;

              if (group[key]) {
                group[key].push(row);
              } else {
                group[key] = [row];
              }
              return group;
            },
            {} as Record<string, OrderMapItem[]>,
          ),
        ).map(([position, items]) => {
          const [lat, lng] = position.split(',').map(Number);
          const item: MapMarkerItem<OrderMapItem[]> = {
            marker: {
              position: {
                lat,
                lng,
              },
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                fillColor: '#338cff',
                fillOpacity: 1,
                strokeColor: '#fff',
                strokeOpacity: 0.5,
                strokeWeight: 2,
                scale: 5,
              },
              clickable: true,
            },
            data: items,
          };
          return item;
        }),
      ),
    );

    this.restoreMapViewOptionsFromStorage();
  }

  ngOnDestroy() {
    this.view.disconnect();
  }

  mapBoundsChanged({ bounds }: MapBoundsChangedData) {
    const input = bounds
      ? {
          north: bounds.north,
          east: bounds.east,
          south: bounds.south,
          west: bounds.west,
        }
      : null;

    const first = !this._mapBounds$.value;
    this._mapBounds$.next(input);

    if (first) {
      this.commitBoundsChanges();
      this.init$.next(true);
    }
  }

  commitBoundsChanges() {
    this.boundsChanged.emit(this._mapBounds$.value);
  }

  mapCenterChanged({ center }: MapCenterChangedData) {
    this.storeMapViewOptionsInStorage({ center });
  }

  mapZoomChanged({ zoom }: MapZoomChangedData) {
    this.storeMapViewOptionsInStorage({ zoom });
  }

  async openRescheduleDialog(orderId: number, methodId?: number, slotId?: string) {
    const data: ShipmentFormDialogData = {
      isRequested: true,
      orderId,
      methodId,
      slotId,
    };

    const dialogResult = await firstValueFrom(
      this.shipmentDialogService.openShipmentFormDialog(data).afterClosed(),
    );

    if (!dialogResult) return;

    await firstValueFrom(
      this.orderService
        .rescheduleOrderShipment({
          orderId,
          methodId: dialogResult.methodId,
          slotId: dialogResult.slotId,
        })
        .pipe(tap(() => this.snackbar.open(`Order Rescheduled`))),
    );

    this.view.refresh();
  }

  async openAddressFormDialog(orderId: number, shippingAddress: IAddressInput) {
    const dialogResult = await firstValueFrom(
      this.addressDialogService
        .openAddressInputDialog({
          title: 'Edit Order Address',
          value: shippingAddress,
        })
        .afterClosed(),
    );

    if (!dialogResult) return;

    await firstValueFrom(
      this.cartService
        .assignAddress({
          assignment: AddressAssignment.Shipping,
          orderId,
          address: dialogResult.value,
        })
        .pipe(tap(() => this.snackbar.open(`Order Address Updated`))),
    );

    this.view.refresh();
  }

  private storeMapViewOptionsInStorage(options: OrderMapViewState | null) {
    const state = this.storage.getItem(`${MAP_OPTIONS_STORAGE_KEY}.${this.view.id}`) as
      | OrderMapViewState
      | undefined;

    this.storage.setItem(`${MAP_OPTIONS_STORAGE_KEY}.${this.view.id}`, {
      ...state,
      ...options,
    });
  }

  private restoreMapViewOptionsFromStorage() {
    const state = this.storage.getItem(`${MAP_OPTIONS_STORAGE_KEY}.${this.view.id}`) as
      | OrderMapViewState
      | undefined;

    if (state) {
      this.mapOptions = {
        ...this.mapOptions,
        ...state,
      };
    }
  }
}
