import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ManualPaymentDialogComponent,
  ManualPaymentDialogData,
  ManualPaymentDialogResult,
} from '../components/manual-payment-dialog/manual-payment-dialog.component';
import { OrderPaymentDialogComponent } from '../components/order-payment/order-payment-dialog.component';
import {
  PaymentMethodFormDialogComponent,
  PaymentMethodFormDialogComponentData,
  PaymentMethodFormDialogComponentResult,
} from '../components/payment-method-form/payment-method-form-dialog.component';
import {
  PaymentRefundDialogComponent,
  PaymentRefundDialogData,
  PaymentRefundDialogResult,
} from '../components/payment-refund-dialog/payment-refund-dialog.component';

@Injectable()
export class PaymentsDialogService {
  constructor(private dialogs: MatDialog) {}

  openOrderPaymentDialog(orderId: number) {
    return this.dialogs.open(OrderPaymentDialogComponent, {
      width: '500px',
      disableClose: true,
      data: {
        orderId,
      },
    });
  }

  openPaymentMethodFormDialog(paymentMethodId?: number) {
    return this.dialogs.open<
      PaymentMethodFormDialogComponent,
      PaymentMethodFormDialogComponentData,
      PaymentMethodFormDialogComponentResult
    >(PaymentMethodFormDialogComponent, {
      data: {
        paymentMethodId,
      },
    });
  }

  openPaymentRefundDialog(data: PaymentRefundDialogData) {
    return this.dialogs.open<
      PaymentRefundDialogComponent,
      PaymentRefundDialogData,
      PaymentRefundDialogResult
    >(PaymentRefundDialogComponent, {
      data,
      width: '450px',
    });
  }

  openManualPaymentDialog(data: ManualPaymentDialogData) {
    return this.dialogs.open<
      ManualPaymentDialogComponent,
      ManualPaymentDialogData,
      ManualPaymentDialogResult
    >(ManualPaymentDialogComponent, {
      data,
      width: '450px',
    });
  }
}
