<x-data-table [view]="view">
  <span *xDataColumnDef="'id'; let row">
    {{ row.id }}
  </span>

  <x-bulk-order-pill
    *xDataColumnDef="'name'; let row"
    [bulkOrder]="row"
    [matMenuTriggerFor]="rowBulkOrderMenu"
    [matMenuTriggerData]="{ $implicit: row }"
  ></x-bulk-order-pill>

  <mat-menu #rowBulkOrderMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item [routerLink]="['/admin/orders/bulk', order.id, 'detail']">View Bulk Order</a>
    </ng-template>
  </mat-menu>

  <span *xDataColumnDef="'template'; let row">
    <b class="me-2">{{ row.template.name }}</b>
    <x-channel-pill [channel]="row.template.channel"></x-channel-pill>
  </span>

  <ng-container *xDataColumnDef="'authorUser'; let row">
    <x-user-pill
      *ngIf="row.authorUser"
      [user]="row.authorUser"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.authorUser }"
    ></x-user-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'customerUser'; let row">
    <x-user-pill
      *ngIf="row.customerUser"
      [user]="row.customerUser"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.customerUser }"
    ></x-user-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'orderSalesStats'; let row">
    <table class="xd-table" *ngIf="row.orderSalesStats.length > 0; else noStateCounts">
      <tbody>
        <ng-container *ngFor="let stat of row.orderSalesStats">
          <tr>
            <th>Total Paid</th>
            <td>
              {{ stat.sumPaidTotal | money : stat.currency }}
            </td>
          </tr>
          <tr>
            <th>Total Refunded</th>
            <td>{{ stat.sumRefundedTotal | money : stat.currency }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <ng-template #noStateCounts>
      <i>N/A</i>
    </ng-template>
  </ng-container>

  <ng-container *xDataColumnDef="'orderStateCounts'; let row">
    <table class="xd-table" *ngIf="row.orderStateCounts.length > 0; else noStateCounts">
      <tbody>
        <tr *ngFor="let state of row.orderStateCounts">
          <th>{{ state.state }}</th>
          <td>
            {{ state.count ?? 0 }}
          </td>
        </tr>
      </tbody>
    </table>
    <ng-template #noStateCounts>
      <i>N/A</i>
    </ng-template>
  </ng-container>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View</a>
    </ng-template>
  </mat-menu>

  <a
    x-data-button
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['/admin/orders/bulk', row.id, 'detail']"
    label="View Order"
    icon="search"
  ></a>
</x-data-table>
