import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface OrderShippingFeeDialogData {
  shippingMethodName?: string;
  currency: string;
}

export interface OrderShippingFeeDialogResult {
  amount: number;
}

@Component({
  selector: 'x-order-shipping-fee-dialog',
  templateUrl: './order-shipping-fee-dialog.component.html',
  styleUrls: ['./order-shipping-fee-dialog.component.scss'],
})
export class OrderShippingFeeDialogComponent {
  orderShippingFeeForm: UntypedFormGroup = new UntypedFormGroup({
    amount: new UntypedFormControl(0, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderShippingFeeDialogData,
    private dialog: MatDialogRef<OrderShippingFeeDialogComponent>,
  ) {}

  async submit() {
    this.orderShippingFeeForm.updateValueAndValidity();

    if (this.orderShippingFeeForm.invalid) return;

    const input: OrderShippingFeeDialogResult = this.orderShippingFeeForm.value;

    this.dialog.close(input);
  }

  closeDialog() {
    this.dialog.close();
  }
}
