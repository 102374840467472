import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonValidator } from '@x/common/form';

export interface ManualPaymentDialogData {
  currency: string;
}

export interface ManualPaymentDialogResult {
  reference: string;
  description: string;
}

@Component({
  selector: 'x-manual-payment-dialog',
  templateUrl: './manual-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ManualPaymentDialogComponent {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    reference: new UntypedFormControl(null, [
      Validators.required,
      Validators.minLength(5),
      CommonValidator.slug(),
    ]),
    description: new UntypedFormControl(null, [Validators.required, Validators.minLength(3)]),
    amount: new UntypedFormControl(null, [Validators.min(0)]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ManualPaymentDialogData,
    private dialog: MatDialogRef<ManualPaymentDialogComponent>,
  ) {}

  async submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const input: ManualPaymentDialogResult = this.formGroup.value;

    this.dialog.close(input);
  }
}
