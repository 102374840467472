<x-data-table [view]="view" [selectable]="selectable" [activatable]="activatable">
  <span *xDataColumnDef="'id'; let row">
    {{ row.id }}
  </span>

  <span *xDataColumnDef="'incident'; let row">
    @if (row.errorDescription; as errorDescription) {
      <x-pill color="warn" [matTooltip]="errorDescription">
        <fa-icon icon="triangle-exclamation"></fa-icon>
      </x-pill>
    } @else {
      <x-pill [matTooltip]="'Incident Description: ' + row.description">
        <fa-icon icon="info-circle"></fa-icon>
      </x-pill>
    }
  </span>

  <ng-container *xDataColumnDef="'order'; let row">
    <x-order-pill
      [order]="row.order"
      [matMenuTriggerFor]="rowOrderMenu"
      [matMenuTriggerData]="{ $implicit: row.order }"
    ></x-order-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'orderCustomer'; let row">
    <x-user-pill
      *ngIf="row.order.user"
      [user]="row.order.user"
      [matMenuTriggerFor]="rowOrderMenu"
      [matMenuTriggerData]="{ $implicit: row.order.user }"
    ></x-user-pill>
  </ng-container>

  <span *xDataColumnDef="'category'; let row" class="mat-subtitle-2">
    {{ row.category?.name }}
  </span>

  <ng-container *xDataColumnDef="'approvalState'; let row">
    <x-pill
      [text]="row.approvalState | l"
      [color]="
        row.approvalState === 'PENDING'
          ? 'amber'
          : row.approvalState === 'APPROVED'
            ? 'light-green'
            : row.approvalState === 'DENIED'
              ? 'warn'
              : 'default'
      "
    ></x-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'resolutionState'; let row">
    <x-pill
      [text]="row.resolutionState | l"
      [color]="
        row.resolutionState === 'UNRESOLVED'
          ? 'default'
          : row.resolutionState === 'RESOLVED' || row.resolutionState === 'SKIPPED'
            ? 'light-green'
            : row.resolutionState === 'FAILED'
              ? 'warn'
              : 'default'
      "
    ></x-pill>
  </ng-container>
  <ng-container *xDataColumnDef="'replacementOrder'; let row">
    <x-order-pill
      *ngIf="row.replacementOrder"
      [order]="row.replacementOrder"
      [matMenuTriggerFor]="rowOrderMenu"
      [matMenuTriggerData]="{ $implicit: row.replacementOrder }"
    ></x-order-pill>
  </ng-container>

  <mat-menu #rowOrderMenu>
    <ng-template matMenuContent let-order>
      <a mat-menu-item [routerLink]="['/admin/orders', order.id, 'detail']">View</a>
    </ng-template>
  </mat-menu>

  <ng-container *xDataColumnDef="'authorUser'; let row">
    <x-user-pill
      *ngIf="row.authorUser"
      [user]="row.authorUser"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.authorUser }"
    ></x-user-pill>
  </ng-container>

  <ng-container *xDataColumnDef="'approverUser'; let row">
    <x-user-pill
      *ngIf="row.approverUser"
      [user]="row.approverUser"
      [matMenuTriggerFor]="rowUserMenu"
      [matMenuTriggerData]="{ $implicit: row.approverUser }"
    ></x-user-pill>
  </ng-container>

  <mat-menu #rowUserMenu>
    <ng-template matMenuContent let-user>
      <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View</a>
    </ng-template>
  </mat-menu>

  <span *xDataColumnDef="'createdAt'; let row" class="mat-subtitle-2">
    {{ row.createdAt | date: 'short' }}
  </span>

  <button
    x-data-button
    *xDataAction="'edit'; let row; primary: true"
    [routerLink]="['/admin/orders/incidents/', row.id, 'form']"
    label="View Incident"
    icon="edit"
  ></button>

  <button
    x-data-button
    *xDataAction="'publish'; let row; when: canPublish.bind(this)"
    label="Publish for Approval"
    (click)="publish(row.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'unpublish'; let row; when: canUnpublish.bind(this)"
    label="Unpublish"
    (click)="unpublish(row.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'approve'; let row; when: canApprove.bind(this)"
    label="Approve"
    (click)="approve(row.id)"
  ></button>

  <button
    x-data-button
    *xDataAction="'deny'; let row; when: canDeny.bind(this)"
    label="Deny"
    (click)="deny(row.id)"
  ></button>
</x-data-table>
