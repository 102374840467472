<h2 mat-dialog-title>Create Bulk Order</h2>
<form
  mat-dialog-content
  [formGroup]="createBulkOrderForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="create_bulk_order_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
      <mat-form-field class="flex-grow-1">
        <mat-label>Order Template</mat-label>
        <x-data-autocomplete
          [provider]="Providers.OrderTemplateItemCollectionProvider"
          placeholder="Find Existing Order Template..."
          formControlName="templateId"
        ></x-data-autocomplete>

        <button matSuffix mat-button (click)="createNewTemplate()">
          <fa-icon icon="plus"></fa-icon>
          New
        </button>
      </mat-form-field>

      <mat-form-field class="flex-grow-1">
        <mat-label>Customer</mat-label>
        <x-data-autocomplete
          [provider]="Providers.UserItemCollectionProvider"
          placeholder="Find customer..."
          formControlName="customerUserId"
        ></x-data-autocomplete>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="closeDialog()">Cancel</button>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    form="create_bulk_order_form"
    [disabled]="createBulkOrderForm.invalid"
  >
    Create
  </button>
</div>
