import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IBulkOrderRowObject } from '@x/ecommerce/domain-client';
import { BulkOrderFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-bulk-order-table',
  templateUrl: 'bulk-order-table.component.html',
  styleUrls: ['bulk-order-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-bulk-order-table',
  },
})
export class BulkOrderTableComponent implements OnInit {
  @Input()
  view: DataTableView<IBulkOrderRowObject, BulkOrderFilterInput, any, number>;

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.view.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }
}
