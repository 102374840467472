<h2 mat-dialog-title>Order Shipping Fee</h2>
<form
  mat-dialog-content
  [formGroup]="orderShippingFeeForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="create_order_form"
>
  <x-dashboard-form-layout>
    <h3>Shipping Method: {{ data.shippingMethodName }}</h3>
    <x-form-panel>
      <mat-form-field>
        <x-currency-input-control
          formControlName="amount"
          required
          [currency]="data.currency"
        ></x-currency-input-control>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-button
    type="submit"
    color="primary"
    form="create_order_form"
    [disabled]="orderShippingFeeForm.invalid"
  >
    Add Shipping Fee
  </button>
</div>
