<h2 mat-dialog-title>Reschedule Shipment</h2>
<form [formGroup]="formGroup" mat-dialog-content>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Shipping Method</mat-label>
        <x-data-select
          formControlName="methodId"
          [provider]="Providers.ShippingMethodItemCollectionProvider"
          (dataChange)="onMethodChange($event)"
          [page]="{ index: 0, size: 100 }"
        ></x-data-select>
      </mat-form-field>
      <mat-form-field *ngIf="methodIdControl.value as methodId">
        <mat-label>Shipping Slot</mat-label>
        <x-data-select
          formControlName="slotId"
          [provider]="Providers.ShippingSlotItemCollectionProvider"
          [filter]="{ methodId }"
        ></x-data-select>
        <button type="button" mat-icon-button matSuffix (click)="selectFulfilmentInterval()">
          <fa-icon icon="calendar"></fa-icon>
        </button>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <div style="display: flex; align-items: center">
    <mat-date-range-input [formGroup]="dateRangeGroup" [rangePicker]="picker">
      <input matStartDate formControlName="start" placeholder="Start date" />
      <input matEndDate formControlName="end" placeholder="End date" />
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)="submit()">Update</button>
</div>
