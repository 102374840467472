import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CartAdjustmentDialogData {
  currency: string;
  label?: string;
  amount?: number;
}
export interface CartAdjustmentDialogResult {
  label: string;
  amount: number;
}

@Component({
  selector: 'x-cart-adjustment-dialog',
  templateUrl: './cart-adjustment-dialog.component.html',
  styleUrls: ['./cart-adjustment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CartAdjustmentDialogComponent {
  amountControl = new UntypedFormControl(this.data.label ?? null, [Validators.required]);
  labelControl = new UntypedFormControl(this.data.amount ?? null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    amount: this.amountControl,
    label: this.labelControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: CartAdjustmentDialogData,
    private dialog: MatDialogRef<CartAdjustmentDialogComponent, CartAdjustmentDialogResult>,
  ) {}

  update() {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      const value = this.formGroup.value;
      this.dialog.close({
        amount: -Number(value.amount),
        label: value.label,
      });
    }
  }
}
