import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateCartInput } from '@x/schemas/ecommerce';

export interface OrderRecreateDialogData {
  skipAgreements?: boolean;
}

export interface OrderRecreateDialogResult {
  skipAgreements: boolean;
}

class RecreateOrderForm extends UntypedFormGroup {
  constructor(controls: {
    [P in keyof Pick<CreateCartInput, 'skipAgreements'>]: AbstractControl;
  }) {
    super(controls);
  }
}

@Component({
  selector: 'x-order-recreate-dialog',
  templateUrl: './order-recreate-dialog.component.html',
})
export class OrderRecreateDialogComponent {
  recreateOrderForm: UntypedFormGroup = new RecreateOrderForm({
    skipAgreements: new UntypedFormControl(this.data.skipAgreements ?? true, Validators.required),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderRecreateDialogData,
    private dialog: MatDialogRef<OrderRecreateDialogComponent>,
  ) {}

  async submit() {
    this.recreateOrderForm.updateValueAndValidity();

    if (this.recreateOrderForm.invalid) return;

    const input: OrderRecreateDialogResult = this.recreateOrderForm.value;

    this.dialog.close(input);
  }

  closeDialog() {
    this.dialog.close();
  }
}
