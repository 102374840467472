import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IPaymentDetailObject } from '@x/ecommerce/domain-client';

export interface PeachEftPaymentDialogData {
  payment: IPaymentDetailObject;
}
export type PeachEftPaymentDialogResultStatus =
  | 'canceled'
  | 'processing'
  // | 'requires_action'
  // | 'requires_capture'
  // | 'requires_confirmation'
  // | 'requires_payment_method'
  | 'succeeded'
  | 'error';

export interface PeachEftPaymentDialogResult {
  status?: PeachEftPaymentDialogResultStatus;
}

@Component({
  selector: 'x-peach-eft-payment-dialog',
  templateUrl: './peach-eft-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./peach-eft-payment-dialog.component.scss'],
})
export class PeachEftPaymentDialogComponent implements OnInit {
  frameSrc: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialogRef<PeachEftPaymentDialogData, PeachEftPaymentDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: PeachEftPaymentDialogData,
  ) {}

  async ngOnInit() {
    // check attrs
    if (!this.data.payment.publicAttributes?.checkoutFormUrl) {
      throw new Error('Peach payment checkoutFormUrl not provided');
    }

    this.frameSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.payment.publicAttributes?.checkoutFormUrl,
    );
  }
}
