import { Component, Input } from '@angular/core';
import { CartObject } from '@x/ecommerce/domain-client';

export type IOrderTotalsOrder = Pick<
  CartObject,
  | 'currency'
  | 'itemsTotal'
  | 'creditItemsTotal'
  | 'subtotal'
  | 'adjustmentsTotal'
  | 'taxTotal'
  | 'total'
  | 'paidTotal'
  | 'refundedTotal'
  | 'balance'
>;

@Component({
  selector: 'x-order-totals',
  templateUrl: './order-totals.component.html',
  styleUrls: ['./order-totals.component.scss'],
})
export class OrderTotalsComponent {
  @Input()
  order: IOrderTotalsOrder;
}
