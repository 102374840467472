import { Component, Inject } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChannelItemCollectionProvider } from '@x/ecommerce/domain-data';
import { CreateCartInput } from '@x/schemas/ecommerce';

export interface OrderRecreateDialogData {
  skipAgreements?: boolean;
}

export interface OrderCreateDialogData {
  channelId?: number;
  locale?: number;
  skipAgreements?: boolean;
}

export interface OrderCreateDialogResult {
  channelId: number;
  locale: string;
  skipAgreements: boolean;
}

class CreateOrderForm extends UntypedFormGroup {
  constructor(controls: {
    [P in keyof CreateCartInput]: AbstractControl;
  }) {
    super(controls);
  }
}

@Component({
  selector: 'x-order-create-dialog',
  templateUrl: './order-create-dialog.component.html',
  styleUrls: ['./order-create-dialog.component.scss'],
})
export class OrderCreateDialogComponent {
  Providers = {
    ChannelItemCollectionProvider,
  };

  createOrderForm: UntypedFormGroup = new CreateOrderForm({
    channelId: new UntypedFormControl(this.data.channelId ?? null, Validators.required),
    locale: new UntypedFormControl(this.data.locale ?? 'en-ZA', Validators.required),
    skipAgreements: new UntypedFormControl(this.data.skipAgreements ?? true, Validators.required),
  });

  get channelId(): number {
    return this.createOrderForm.get('channelId')?.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OrderCreateDialogData,
    private dialog: MatDialogRef<OrderCreateDialogComponent>,
  ) {}

  async submit() {
    this.createOrderForm.updateValueAndValidity();

    if (this.createOrderForm.invalid) return;

    const input: OrderCreateDialogResult = this.createOrderForm.value;

    this.dialog.close(input);
  }

  closeDialog() {
    this.dialog.close();
  }
}
