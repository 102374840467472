import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  GoogleMap,
  MapInfoWindow,
  MapMarker,
  MapPolygon,
  MarkerClustererOptions,
} from '@angular/google-maps';
import { Subject, debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs';
import { MAP_MODULE_CONFIG, MapModuleConfig } from '../map-module.config';
import { DEFAULT_THEME } from '../map.theme';

const DEFAULT_MAP_OPTIONS: google.maps.MapOptions = {
  styles: DEFAULT_THEME,
  backgroundColor: 'transparent',
  disableDefaultUI: true,
  minZoom: 3,
  controlSize: 32,
  zoomControl: true,
  scaleControl: true,
  mapTypeControl: true,
  mapTypeControlOptions: {
    style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
    position: google.maps.ControlPosition.TOP_RIGHT,
  },
};

const DEFAULT_CLUSTER_OPTIONS: MarkerClustererOptions = {
  minimumClusterSize: 2,
  enableRetinaIcons: true,
  zoomOnClick: true,
  averageCenter: false,
  maxZoom: 20,
};

const DEFAULT_INFO_WINDOW_OPTIONS: google.maps.InfoWindowOptions = {
  maxWidth: 300,
  minWidth: 100,
};

const IMAGE_PATH =
  'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

export type MapMarkerItem<T = any> = {
  marker: google.maps.MarkerOptions;
  data: T;
};

export type MapPolygonItem<T = any> = {
  polygon: google.maps.PolygonOptions;
  data: T;
};

export type MapBoundsChangedData = {
  bounds?: google.maps.LatLngBoundsLiteral | null;
};

export type MapCenterChangedData = {
  center?: google.maps.LatLngLiteral | null;
};

export type MapZoomChangedData = {
  zoom?: number;
};

export type MapClickedData<T> = {
  item: T;
};

export type MapDragEndedData<T> = {
  item: T;
  latLng?: google.maps.LatLngLiteral;
};

@Component({
  selector: 'x-map',
  templateUrl: './map.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
        width: 100%;
      }

      google-map {
        display: block;
        height: 100%;
        width: 100%;
      }

      ::ng-deep .gm-style .gm-style-iw-c {
        border-radius: 5px;
        padding: 0;
        min-width: 0;
        .gm-style-iw-d {
          overflow: hidden !important;
        }

        & > button {
          top: 3px !important;
          right: 3px !important;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapComponent implements OnDestroy, AfterViewInit {
  private readonly _destroy$ = new Subject<void>();

  IMAGE_PATH = IMAGE_PATH;

  @ViewChild('mapControl', { static: false })
  mapControl: GoogleMap;

  @Input()
  set mapOptions(value: google.maps.MapOptions) {
    this._mapOptions = {
      ...this._mapOptions,
      ...value,
    };
  }
  get mapOptions() {
    return this._mapOptions;
  }
  private _mapOptions: google.maps.MapOptions = {
    ...DEFAULT_MAP_OPTIONS,
    center: this.mapConfig.defaultCenter,
    zoom: this.mapConfig.defaultZoom,
  };

  @Input()
  set clusterOptions(value: MarkerClustererOptions) {
    this._clusterOptions = {
      ...this._clusterOptions,
      ...value,
    };
  }
  get clusterOptions() {
    return this._clusterOptions;
  }
  private _clusterOptions: MarkerClustererOptions = DEFAULT_CLUSTER_OPTIONS;

  @Input()
  set infoWindowOptions(value: google.maps.InfoWindowOptions) {
    this._infoWindowOptions = {
      ...this._infoWindowOptions,
      ...value,
    };
  }
  get infoWindowOptions() {
    return this._infoWindowOptions;
  }
  private _infoWindowOptions: google.maps.InfoWindowOptions = DEFAULT_INFO_WINDOW_OPTIONS;

  @Input() markers: Array<MapMarkerItem> | null = null;
  @Input() polygons: Array<MapPolygonItem> | null = null;
  @Input() infoWindowTemplate: TemplateRef<any>;

  @Output() boundsChanged = new EventEmitter<MapBoundsChangedData>();
  @Output() centerChanged = new EventEmitter<MapCenterChangedData>();
  @Output() zoomChanged = new EventEmitter<MapZoomChangedData>();
  @Output() markerClicked = new EventEmitter<MapClickedData<MapMarkerItem>>();
  @Output() markerDragEnded = new EventEmitter<MapDragEndedData<MapMarkerItem>>();
  @Output() polygonClicked = new EventEmitter<MapClickedData<MapPolygonItem>>();
  @Output() polygonDragEnded = new EventEmitter<MapDragEndedData<MapPolygonItem>>();

  get bounds$() {
    return this.mapControl.boundsChanged.pipe(
      startWith(this.mapControl.getBounds()),
      map(() => this.mapControl.getBounds()),
      debounceTime(250),
      distinctUntilChanged(),
    );
  }

  get center$() {
    return this.mapControl.centerChanged.pipe(
      startWith(this.mapControl.getCenter()),
      map(() => this.mapControl.getCenter()),
      debounceTime(250),
      distinctUntilChanged(),
    );
  }

  get zoom$() {
    return this.mapControl.zoomChanged.pipe(
      startWith(this.mapControl.getZoom()),
      map(() => this.mapControl.getZoom()),
      debounceTime(250),
      distinctUntilChanged(),
    );
  }

  constructor(
    @Inject(MAP_MODULE_CONFIG) private readonly mapConfig: MapModuleConfig,
    private readonly changeRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.bounds$.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe((v) => {
      const bounds = v?.toJSON();
      this.boundsChanged.emit({ bounds });
    });

    this.center$.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe((v) => {
      const center = v?.toJSON();
      this.centerChanged.emit({ center });
    });

    this.zoom$.pipe(takeUntil(this._destroy$), distinctUntilChanged()).subscribe((zoom) => {
      this.zoomChanged.emit({ zoom });
    });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  markerClick(item: MapMarkerItem, marker: MapMarker, infoWindow: MapInfoWindow) {
    if (this.infoWindowTemplate) {
      infoWindow.open(marker);
    }

    this.markerClicked.emit({ item });
    this.changeRef.markForCheck();
  }
  markerDragEnd(event: google.maps.MapMouseEvent, item: MapMarkerItem, marker: MapMarker) {
    this.markerDragEnded.emit({ item, latLng: event.latLng?.toJSON() });
  }

  polygonClick(item: MapPolygonItem, polygon: MapPolygon) {
    this.polygonClicked.emit({ item });
  }
  polygonDragEnd(event: google.maps.MapMouseEvent, item: MapPolygonItem, polygon: MapPolygon) {
    this.polygonDragEnded.emit({ item, latLng: event.latLng?.toJSON() });
  }

  trackByPosition(index: number, item: any) {
    return item.position;
  }
}
