import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { IPaymentDetailObject } from '@x/ecommerce/domain-client';

export interface IframePaymentDialogData {
  payment: IPaymentDetailObject;
}

export interface IframePaymentDialogResult {}

@Component({
  selector: 'x-iframe-payment-dialog',
  templateUrl: './iframe-payment-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./iframe-payment-dialog.component.scss'],
})
export class IframePaymentDialogComponent implements OnInit {
  frameSrc: SafeUrl;

  constructor(
    private sanitizer: DomSanitizer,
    public dialog: MatDialogRef<IframePaymentDialogComponent, IframePaymentDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: IframePaymentDialogData,
  ) {}

  async ngOnInit() {
    // check attrs
    if (!this.data.payment.publicAttributes?.checkoutFormUrl) {
      throw new Error('checkoutFormUrl not provided');
    }

    this.frameSrc = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.data.payment.publicAttributes?.checkoutFormUrl,
    );
  }
}
