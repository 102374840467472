import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PaymentRefundDialogData {
  currency: string;
  amount?: number;
}

export interface PaymentRefundDialogResult {
  amount: number | null;
  note: string;
}

@Component({
  selector: 'x-payment-refund-dialog',
  templateUrl: 'payment-refund-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: true,
})
export class PaymentRefundDialogComponent {
  currency = this.data.currency;

  amountControl = new UntypedFormControl(this.data.amount ?? 0);
  noteControl = new UntypedFormControl(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    amount: this.amountControl,
    note: this.noteControl,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: PaymentRefundDialogData,
    private dialogRef: MatDialogRef<PaymentRefundDialogComponent, PaymentRefundDialogResult>,
  ) {}

  submit() {
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) return;

    const { amount, note } = this.formGroup.value;

    const data: PaymentRefundDialogResult = {
      amount,
      note,
    };

    this.dialogRef.close(data);
  }
}
