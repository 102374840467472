<h2 mat-dialog-title>Order Payment</h2>
<div mat-dialog-content *ngIf="orderView.data as order">
  <ng-container *ngIf="order.paymentState === 'AWAITING_PAYMENT'; else notQuoted">
    <mat-list>
      <mat-list-item>
        <span matListItemTitle>Order ID{{ order.id }}</span>
        <span matListItemLine>Amount paid: {{ order.paidTotal | money : order.currency }}</span>
        <span matListItemLine>
          Amount outstanding: {{ order.outstandingTotal | money : order.currency }}
        </span>
      </mat-list-item>
    </mat-list>
  </ng-container>

  <ng-template #notQuoted>Order must be in a quoted state to create payments.</ng-template>
</div>
<div mat-dialog-actions>
  <button mat-flat-button mat-dialog-close>Done</button>
</div>
