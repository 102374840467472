import type { IDataTableViewOptions } from '@x/common/data';

export const ORDER_INCIDENT_TABLE_VIEW_OPTIONS: IDataTableViewOptions = {
  id: 'order_incident_index',
  columns: [
    {
      id: 'id',
      title: 'ID',
    },
    {
      id: 'incident',
      title: 'Incident',
    },
    {
      id: 'order',
      title: 'Order',
    },
    {
      id: 'orderCustomer',
      title: 'Customer',
    },
    {
      id: 'category',
      title: 'Category',
    },
    {
      id: 'approvalState',
      title: 'Approval State',
    },
    {
      id: 'resolutionState',
      title: 'Resolution State',
    },
    {
      id: 'replacementOrder',
      title: 'Replacement Order',
    },
    {
      id: 'authorUser',
      title: 'Author',
    },
    {
      id: 'approverUser',
      title: 'Approver',
    },
    {
      id: 'createdAt',
      title: 'Created Date',
    },
  ],
  sorts: [
    {
      id: 'id',
      title: 'ID',
    },
  ],
  defaultColumns: [
    'id',
    'incident',
    'category',
    'approvalState',
    'resolutionState',
    'order',
    'replacementOrder',
    'authorUser',
    'approverUser',
    'createdAt',
  ],
  page: { index: 0, size: 50 },
  pageSizes: [20, 50, 100, 200],
  sort: {
    column: 'id',
    order: 'desc',
  },
};
