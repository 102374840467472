<h2 mat-dialog-title>Delivery Slots</h2>
<mat-dialog-content *ngIf="intervals$ | async as intervals">
  <mat-radio-group [formControl]="control">
    <xd-list-item
      *ngFor="let interval of intervals; trackBy: trackById"
      [icon]="interval.isAvailable ? 'calendar-day' : 'calendar-xmark'"
    >
      <p xdHeadline [style.textDecoration]="interval.isAvailable ? 'none' : 'line-through'">
        <span *ngIf="interval.slotAvailability as slot; else noSlot">
          {{ slot.slotStartAt | datetime : 'fullDate' }}
          {{ slot.slotStartAt | datetime : 'shortTime' }} -
          {{ slot.slotEndAt | datetime : 'shortTime' }}
        </span>

        <ng-template #noSlot>
          {{ interval.interval.start | datetime : 'EEE, MMMM d, y' }},
          {{ interval.interval.start | datetime : 'shortTime' }}
        </ng-template>
      </p>
      <p xdLine *ngIf="detailControl.value">
        Slot ID:
        <span *ngIf="interval.slotAvailability?.slotId">
          [{{ interval.slotAvailability?.slotId }}]
        </span>
      </p>
      <p xdLine *ngIf="detailControl.value">
        Shipping slot available:
        <fa-icon [icon]="interval.slotAvailability?.isAvailable ? 'check' : 'x'"></fa-icon>
        <span *ngIf="interval.slotAvailability?.unavailableReason">
          [{{ interval.slotAvailability?.unavailableReason }}]
        </span>
      </p>
      <p xdLine *ngIf="detailControl.value">
        Shipping slot available capacity:
        {{ interval.slotAvailability?.availableCapacity ?? 'none' }}
      </p>
      <p xdLine *ngIf="detailControl.value">
        Stock available:
        <fa-icon [icon]="interval.stockAvailability?.isAvailable ? 'check' : 'x'"></fa-icon>
      </p>
      <p xdLine *ngIf="detailControl.value">
        Warehouse: {{ interval.stockAvailability?.warehouse?.name }}
      </p>
      <span xdLine *ngIf="detailControl.value">
        <ng-container *ngIf="interval.stockAvailability?.availableQuantities as quantities">
          <ng-container *ngFor="let quantity of quantities">
            <small>
              {{ quantity.item.sku }}, {{ quantity.quantity }} in stock,
              {{ quantity.requiredQuantity }} required.
              <br />
            </small>
          </ng-container>
        </ng-container>
      </span>
      <mat-radio-button [value]="interval"></mat-radio-button>
    </xd-list-item>
  </mat-radio-group>
</mat-dialog-content>
<div mat-dialog-actions align="end">
  <mat-slide-toggle [formControl]="detailControl">Show Details</mat-slide-toggle>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)="submit()">Update</button>
</div>
