import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { DataView } from '@x/common/data';
import { IOrderIncidentDetailObject } from '@x/ecommerce/domain-client';
import { OrderIncidentActionsService } from '../../services/order-incident-actions.service';

export type InfoPanelDisplayMode = 'visible' | 'hidden';
export type ModifiableInfoPanelDisplayMode = 'readonly' | InfoPanelDisplayMode;

@Component({
  selector: 'x-order-incident-info-panel',
  templateUrl: './order-incident-info-panel.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class OrderIncidentInfoPanelComponent implements OnInit, OnDestroy {
  @Input() view: DataView<IOrderIncidentDetailObject, number>;

  @Input() showIncidentMenu = true;
  @Input() incidentDetails: InfoPanelDisplayMode = 'visible';
  @Input() approverInfo: ModifiableInfoPanelDisplayMode = 'hidden';
  @Input() originalOrderInfo: InfoPanelDisplayMode = 'hidden';
  @Input() replacementOrderInfo: InfoPanelDisplayMode = 'hidden';

  trackById = (i: number, item: { id: number }) => item.id;

  constructor(public readonly orderIncidentActions: OrderIncidentActionsService) {}

  ngOnInit(): void {
    this.view.connect();
  }
  ngOnDestroy(): void {
    this.view.disconnect();
  }
}
