<h2 mat-dialog-title>Payment Method</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  id="payment_method_form"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Payment Method Name</mat-label>
        <input matInput type="text" formControlName="name" required autocomplete="disabled" />
        <mat-hint>Only visible to administrators</mat-hint>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Channels</mat-label>
        <mat-select formControlName="channels" multiple>
          <mat-option *ngFor="let channel of channels$ | async" [value]="channel.code">
            {{ channel.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Payment Provider</mat-label>
        <mat-select formControlName="provider">
          <mat-option *ngFor="let provider of providers$ | async" [value]="provider.id">
            {{ provider.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Payment Provider Config</mat-label>
        <textarea
          formControlName="providerConfig"
          matInput
          xInputDense
          rows="4"
          cdkTextareaAutosize
          cdkAutosizeMinRows="4"
        ></textarea>
        <mat-hint>JSON format</mat-hint>
        <mat-error xFormError="providerConfig"></mat-error>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    color="primary"
    form="payment_method_form"
  >
    Save
  </button>
</div>
