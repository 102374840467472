import { Component, Inject, Optional } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ChannelItemCollectionProvider,
  OrderTemplateItemCollectionProvider,
  UserItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { CreateBulkOrderInput } from '@x/schemas/ecommerce';
import { OrderDialogService } from '../../services/order-dialog.service';

export interface BulkOrderCreateDialogData {
  name?: string;
  templateId?: number;
}

export interface BulkOrderCreateDialogResult {
  name: string;
  templateId: number;
}

class CreateBulkOrderForm extends UntypedFormGroup {
  constructor(controls: { [P in keyof CreateBulkOrderInput]: AbstractControl }) {
    super(controls);
  }
}

@Component({
  selector: 'x-bulk-order-create-dialog',
  templateUrl: './bulk-order-create-dialog.component.html',
  styleUrls: ['./bulk-order-create-dialog.component.scss'],
})
export class BulkOrderCreateDialogComponent {
  Providers = {
    ChannelItemCollectionProvider,
    OrderTemplateItemCollectionProvider,
    UserItemCollectionProvider,
  };

  createBulkOrderForm: UntypedFormGroup = new CreateBulkOrderForm({
    name: new FormControl<string | null>(this.data?.name ?? null, Validators.required),
    templateId: new FormControl<number | null>(this.data?.templateId ?? null, Validators.required),
    customerUserId: new FormControl<number | null>(this.data?.templateId ?? null),
  });

  get channelId(): number {
    return this.createBulkOrderForm.get('channelId')?.value;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data: BulkOrderCreateDialogData,
    private dialog: MatDialogRef<BulkOrderCreateDialogComponent>,
    private orderDialogs: OrderDialogService,
  ) {}

  async submit() {
    this.createBulkOrderForm.updateValueAndValidity();

    if (this.createBulkOrderForm.invalid) return;

    const input: BulkOrderCreateDialogResult = this.createBulkOrderForm.value;

    this.dialog.close(input);
  }

  createNewTemplate() {
    this.orderDialogs
      .openOrderTemplateFormDialog({
        templateName: this.createBulkOrderForm.get('name')?.value,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.createBulkOrderForm.get('templateId')?.setValue(result.templateId);
        }
      });
  }

  closeDialog() {
    this.dialog.close();
  }
}
