import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IPaymentDetailObject } from '@x/ecommerce/domain-client';
import { PaymentState } from '@x/schemas/ecommerce';
import {
  IcarePaymentDialogComponent,
  IcarePaymentDialogData,
  IcarePaymentDialogResult,
} from '../components/icare-payment-dialog/icare-payment-dialog.component';
import {
  IframePaymentDialogComponent,
  IframePaymentDialogData,
  IframePaymentDialogResult,
} from '../components/iframe-payment-dialog/iframe-payment-dialog.component';
import {
  PeachEftPaymentDialogComponent,
  PeachEftPaymentDialogData,
  PeachEftPaymentDialogResult,
} from '../components/peach-eft-payment-dialog/peach-eft-payment-dialog.component';

export type PaymentProviderDialogOpenerFn = (payment: IPaymentDetailObject) => MatDialogRef<any>;

@Injectable()
export class PaymentProviderDialogService {
  constructor(private dialogs: MatDialog) {}

  open(payment: IPaymentDetailObject) {
    const provider = payment.method.provider;

    switch (payment.state) {
      case PaymentState.Processing:
        return this.openIframePaymentDialog(payment);
      case PaymentState.Redemption:
        return this.openIcarePaymentDialog(payment);
      case PaymentState.Completed:
      case PaymentState.Failed:
      default:
        throw new Error(`Unsupported payment state '${payment.state}'`);
    }
  }

  openIframePaymentDialog(payment: IPaymentDetailObject) {
    return this.dialogs.open<
      IframePaymentDialogComponent,
      IframePaymentDialogData,
      IframePaymentDialogResult
    >(IframePaymentDialogComponent, {
      width: '700px',
      data: {
        payment,
      },
    });
  }

  openPeachEftPaymentDialog(payment: IPaymentDetailObject) {
    return this.dialogs.open<
      PeachEftPaymentDialogComponent,
      PeachEftPaymentDialogData,
      PeachEftPaymentDialogResult
    >(PeachEftPaymentDialogComponent, {
      width: '100vh',
      height: '100vh',
      data: {
        payment,
      },
    });
  }

  openIcarePaymentDialog(payment: IPaymentDetailObject) {
    return this.dialogs.open<
      IcarePaymentDialogComponent,
      IcarePaymentDialogData,
      IcarePaymentDialogResult
    >(IcarePaymentDialogComponent, {
      width: '500px',
      height: '210px',
      data: {
        payment,
      },
    });
  }
}
