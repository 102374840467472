<h2 mat-dialog-title>Create Order</h2>
<form
  mat-dialog-content
  [formGroup]="formGroup"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="manual_payment_form"
  xFormErrorBoundary
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Payment Reference</mat-label>
        <input matInput type="text" formControlName="reference" required />
        <mat-hint>Unique transaction ID from Peach/EFT payment.</mat-hint>
        <mat-error xFormError="reference"></mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Payment Description</mat-label>
        <input matInput type="text" formControlName="description" required />
        <mat-hint>Describe why a manual payment took place.</mat-hint>
        <mat-error xFormError="description"></mat-error>
      </mat-form-field>

      <mat-form-field floatLabel="always">
        <mat-label>Amount</mat-label>
        <x-currency-input-control
          formControlName="amount"
          [currency]="data.currency"
          placeholder="Full Amount Outstanding"
        ></x-currency-input-control>
        <mat-hint>Leave blank for full outstanding amount</mat-hint>
        <mat-error xFormError="amount"></mat-error>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="accent" mat-dialog-close>Cancel</button>
  <button mat-button type="submit" color="primary" form="manual_payment_form">Create</button>
</div>
