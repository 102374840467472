import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderTemplateService } from '@x/ecommerce/domain-client';
import {
  ChannelItemCollectionProvider,
  OrderTemplateItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { CreateOrderTemplateInput } from '@x/schemas/ecommerce';

export interface OrderTemplateFormDialogData {
  templateId?: number;
  templateName?: string;
}

export interface OrderTemplateFormDialogResult {
  templateId: number;
}

class CreateOrderTemplateForm extends UntypedFormGroup {
  constructor(controls: { [P in keyof CreateOrderTemplateInput]: AbstractControl }) {
    super(controls);
  }
}

@Component({
  selector: 'x-order-template-form-dialog',
  templateUrl: './order-template-form-dialog.component.html',
  styleUrls: ['./order-template-form-dialog.component.scss'],
})
export class OrderTemplateFormDialogComponent implements OnInit {
  Providers = {
    ChannelItemCollectionProvider,
    OrderTemplateItemCollectionProvider,
  };

  orderTemplateForm: UntypedFormGroup;

  get channelId(): number {
    return this.orderTemplateForm.get('channelId')?.value;
  }

  constructor(
    private dialog: MatDialogRef<OrderTemplateFormDialogComponent>,
    private orderTemplateService: OrderTemplateService,
    @Inject(MAT_DIALOG_DATA)
    @Optional()
    public data?: OrderTemplateFormDialogData,
  ) {}

  ngOnInit(): void {
    if (this.data?.templateId) {
      // update dialog
      // NOOP for now
      // TODO implement
    } else {
      // create dialog
      this.orderTemplateForm = new CreateOrderTemplateForm({
        name: new FormControl<string | null>(this.data?.templateName ?? null, Validators.required),
        locale: new FormControl<string | null>(null, Validators.required),
        channelId: new FormControl<number | null>(null, Validators.required),
      });
    }
  }

  async submit() {
    this.orderTemplateForm.updateValueAndValidity();
    if (this.orderTemplateForm.invalid) return;

    if (this.data?.templateId) {
      // update dialog
      // NOOP for now
      // TODO implement
    } else {
      // create dialog
      const input: CreateOrderTemplateInput = this.orderTemplateForm.value;
      this.orderTemplateService.create(input).subscribe((result) => {
        this.dialog.close({
          templateId: result.id,
        });
      });
    }
  }

  closeDialog() {
    this.dialog.close();
  }
}
