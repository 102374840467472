<x-content class="p-0">
  <table class="mat-typography cart-summary-table">
    <tbody>
      <tr>
        <th class="mat-body-2">Sales Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.itemsTotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-2">Credit Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.creditItemsTotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-1">Subtotal</th>
        <td class="mat-body-1" style="text-align: right">
          {{ order.subtotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-2">Adjustments Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.adjustmentsTotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-2">Tax Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.taxTotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-1">Order Total</th>
        <td class="mat-body-1" style="text-align: right">
          {{ order.total | money: order.currency }}
        </td>
      </tr>
      <tr *ngIf="order.paidTotal">
        <th class="mat-body-2">Paid Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.paidTotal | money: order.currency }}
        </td>
      </tr>
      <tr *ngIf="order.refundedTotal">
        <th class="mat-body-2">Refunded Total</th>
        <td class="mat-body-2" style="text-align: right">
          {{ order.refundedTotal | money: order.currency }}
        </td>
      </tr>
      <tr>
        <th class="mat-body-1">Balance</th>
        <td class="mat-body-1" style="text-align: right">
          {{ order.balance | money: order.currency }}
        </td>
      </tr>
    </tbody>
  </table>
</x-content>
