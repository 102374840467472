<x-data-table
  [view]="view"
  [selectable]="selectable"
  [activatable]="activatable"
  [reorderableRows]="reorderableRows"
  (rowReorder)="reorder($event)"
>
  <span *xDataColumnDef="'id'; let row">
    {{ row.id }}
  </span>

  <span *xDataColumnDef="'name'; let row">
    {{ row.name }}
  </span>

  <span
    *xDataColumnDef="'description'; let row"
    class="truncate-text"
    [matTooltip]="row.description"
  >
    {{ row.description }}
  </span>

  <span *xDataColumnDef="'requireApproval'; let row">
    <fa-icon [icon]="row.requireApproval ? 'check' : 'times'"></fa-icon>
  </span>

  <span *xDataColumnDef="'allowedOrderStates'; let row">
    {{ row.allowedOrderStates?.join(',') }}
  </span>

  <span *xDataColumnDef="'itemActions'; let row">
    {{ row.itemActions | json }}
  </span>

  <span *xDataColumnDef="'createdAt'; let row">
    {{ row.createdAt | date: 'short' }}
  </span>

  <a
    x-data-button
    *xDataAction="'form'; let row; primary: true"
    [routerLink]="['/admin/orders/incident-categories', row.id, 'form']"
    label="Edit Incident Category"
    icon="edit"
  ></a>
</x-data-table>
