<ng-container *ngIf="view.data as incident">
  <!-- Incident Details -->

  @if (incidentDetails !== 'hidden') {
    <xd-list-item icon="circle-exclamation-check">
      <span xdHeadline>
        <span>Order Incident</span>
      </span>
      <span xdLine>ID: {{ incident.id }}</span>
      <span xdLine>Authored By: {{ incident.authorUser.email }}</span>
      <span xdLine>
        Approval State: {{ 'OrderIncidentApprovalState.' + incident.approvalState | l }}
      </span>
      <span xdLine>
        Resolution State: {{ 'OrderIncidentResolutionState.' + incident.resolutionState | l }}
      </span>
    </xd-list-item>

    @if (view.data.errorDescription; as errorDescription) {
      <xd-list-item class="warning" icon="triangle-exclamation">
        <span xdHeadline>
          <span>Error</span>
        </span>
        <span xdLine>
          {{ errorDescription }}
        </span>
      </xd-list-item>
    }

    <xd-list-item icon="swatchbook">
      <span xdHeadline>
        <span>Category</span>
      </span>
      <span xdLine>
        {{ incident.category?.name }}
        <fa-icon *ngIf="incident.subcategory" icon="chevron-right"></fa-icon>
        {{ incident.subcategory?.name }}
      </span>
    </xd-list-item>

    <xd-list-item icon="signature">
      <span xdHeadline>
        <span>Description</span>
      </span>
      <span xdLine preserveWhitespace>{{ incident.description }}</span>
    </xd-list-item>
  }

  <!-- Approver Info -->
  @if (approverInfo !== 'hidden') {
    <xd-list-item icon="user">
      <span xdHeadline>Approver</span>
      @if (incident.approverUser; as user) {
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
      }

      @if (incident.approverUser; as user) {
        <span xdLine>{{ user.email }}</span>
      }

      @if (incident.approverUser; as user) {
        <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
      }

      @if (incident.approverUser === null || incident.approverUser === undefined) {
        <span xdLine>No Approver Assigned</span>
      }
      <button
        mat-icon-button
        [matMenuTriggerFor]="approverUserMenu"
        [disabled]="approverInfo === 'readonly'"
      >
        <fa-icon icon="ellipsis-v"></fa-icon>
      </button>
      <mat-menu #approverUserMenu="matMenu">
        <ng-template matMenuContent>
          <a
            mat-menu-item
            [disabled]="['APPROVED', 'DENIED'].includes(incident.approvalState)"
            (click)="orderIncidentActions.assignApprover(view)"
          >
            Assign Approver
          </a>
          <a
            mat-menu-item
            [disabled]="incident.approverUser === null"
            (click)="orderIncidentActions.unassignApprover(view)"
          >
            Unassign Approver
          </a>
        </ng-template>
      </mat-menu>
    </xd-list-item>
  }

  <!-- Original Order Info -->
  @if (originalOrderInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item icon="clipboard-list" *ngIf="view.data?.order as order">
      <span xdHeadline>Original Order</span>
      <span xdLine *ngIf="order.number">
        <span [xClip]="order.number">Order #{{ order.number }}</span>
      </span>

      <x-order-totals xdBody *ngIf="incident.order" [order]="incident.order"></x-order-totals>

      <xd-list-item icon="store">
        <span xdHeadline>Channel</span>
        <span xdLine>{{ incident.order.channel.name }}</span>
        <span xdLine>{{ incident.order.locale }}</span>
      </xd-list-item>

      <xd-list-item icon="user" *ngIf="view.data?.order?.user as user">
        <span xdHeadline>Customer</span>
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
        <span xdLine>{{ user.email }}</span>
        <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
        <button mat-icon-button [matMenuTriggerFor]="orderUserMenu">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
        <mat-menu #orderUserMenu="matMenu">
          <ng-template matMenuContent>
            <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
          </ng-template>
        </mat-menu>
      </xd-list-item>

      @if (incident.order.items.length > 0) {
        <xd-expand-item icon="stroopwafel">
          <span xdHeadline>Order Items</span>
          <table class="mat-typography product-summary-table">
            <tbody>
              <tr *ngFor="let item of incident.order.items">
                <td style="text-align: left">
                  <a
                    [routerLink]="[
                      '/admin/inventory/stock-items/detail',
                      item.variant.stockItem?.id
                    ]"
                  >
                    {{ item.variant.stockItem?.sku }}
                  </a>
                </td>
                <td style="text-align: left">
                  {{ item.productName }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </xd-expand-item>
      }

      <x-action-row>
        <a mat-flat-button color="accent" [routerLink]="['/admin/orders', order.id, 'detail']">
          View Order
        </a>
      </x-action-row>
    </xd-expand-item>
  }

  <!-- Replacement Order Info -->
  @if (replacementOrderInfo !== 'hidden') {
    <mat-divider></mat-divider>
    <xd-expand-item
      icon="clipboard-list"
      *ngIf="view.data?.replacementOrder as order"
      [isExpanded]="true"
    >
      <span xdHeadline>Replacement Order</span>
      <span xdLine *ngIf="order.number">
        <span [xClip]="order.number">Order #{{ order.number }}</span>
      </span>

      <x-order-totals xdBody *ngIf="order" [order]="order"></x-order-totals>
      <xd-list-item icon="user" *ngIf="view.data?.replacementOrder?.user as user">
        <span xdHeadline>Customer</span>
        <span xdLine>{{ user.firstName }} {{ user.lastName }}</span>
        <span xdLine>{{ user.email }}</span>
        <span xdLine *ngIf="user.phone">{{ user.phone }}</span>
        <button mat-icon-button [matMenuTriggerFor]="orderUserMenu">
          <fa-icon icon="ellipsis-v"></fa-icon>
        </button>
        <mat-menu #orderUserMenu="matMenu">
          <ng-template matMenuContent>
            <a mat-menu-item [routerLink]="['/admin/users/detail', user.id]">View Customer</a>
          </ng-template>
        </mat-menu>
      </xd-list-item>

      @if (order.items.length > 0) {
        <xd-expand-item icon="stroopwafel">
          <span xdHeadline>Order Items</span>
          <table class="mat-typography product-summary-table">
            <tbody>
              <tr *ngFor="let item of order.items">
                <td style="text-align: left">
                  <a
                    [routerLink]="[
                      '/admin/inventory/stock-items/detail',
                      item.variant.stockItem?.id
                    ]"
                  >
                    {{ item.variant.stockItem?.sku }}
                  </a>
                </td>
                <td style="text-align: left">
                  {{ item.productName }}
                </td>
                <td>{{ item.quantity }}</td>
              </tr>
            </tbody>
          </table>
        </xd-expand-item>
      }

      <x-action-row>
        <a mat-flat-button color="accent" [routerLink]="['/admin/orders', order.id, 'detail']">
          View Order
        </a>
      </x-action-row>
    </xd-expand-item>
  }
  <mat-divider></mat-divider>
</ng-container>
