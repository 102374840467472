import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DataView } from '@x/common/data';
import {
  IOrderDetailObject,
  IOrderDetailProductVariantItemObject,
  IOrderItemDetailObject,
} from '@x/ecommerce/domain-client';
import { OrderDetailProductVariantItemCollectionProvider } from '@x/ecommerce/domain-data';
import { OrderItemSource, OrderState } from '@x/schemas/ecommerce';
import { Subject, takeUntil, tap } from 'rxjs';
import { OrderDialogService } from '../../services/order-dialog.service';

export interface IUpdateOrderItemEvent {
  productVariantId: number;
  quantity: number;
  options?: any;
}
export interface IAdjustOrderItemEvent {
  productVariantId: number;
  amount: number;
  label: string;
}
export interface IRemoveOrderItemEvent {
  productVariantId: number;
  source: OrderItemSource;
}

@Component({
  selector: 'x-order-item-manager',
  templateUrl: './order-item-manager.component.html',
  styleUrl: './order-item-manager.component.scss',
  host: {
    class: 'x-order-item-manager',
  },
})
export class OrderItemManagerComponent implements OnInit, OnDestroy {
  private readonly _destroy$ = new Subject<void>();

  readonly Providers = {
    OrderDetailProductVariantItemCollectionProvider,
  };

  readonly updateItemsAllowedOrderStates = [OrderState.Cart];
  readonly adjustItemsAllowedOrderStates = [OrderState.Cart];
  readonly removeItemsAllowedOrderStates = [OrderState.Cart];

  readonly orderTableColumns: string[] = [
    'alert',
    'id',
    'productName',
    'quantity',
    'unitsTotal',
    'taxTotal',
    'adjustmentsTotal',
    'total',
    'actions',
  ];

  readonly productVariantControl = new FormControl();

  @Input() orderView: DataView<IOrderDetailObject>;

  @Input()
  canUpdateItems = true;

  @Input() canAddShippingFee = true;
  @Input() canModifyItemQuantityPredicate: (item: IOrderItemDetailObject) => boolean = (
    item: IOrderItemDetailObject,
  ) => item.source === OrderItemSource.Order;
  @Input() canAdjustItemPredicate: (item: IOrderItemDetailObject) => boolean = (
    item: IOrderItemDetailObject,
  ) => item.source === OrderItemSource.Order;
  @Input() canRemoveItemPredicate: (item: IOrderItemDetailObject) => boolean = (
    item: IOrderItemDetailObject,
  ) => item.source === OrderItemSource.Order;

  @Output()
  updateOrderItem = new EventEmitter<IUpdateOrderItemEvent>();
  @Output() adjustOrderItem = new EventEmitter<IAdjustOrderItemEvent>();
  @Output() removeOrderItem = new EventEmitter<IRemoveOrderItemEvent>();
  @Output() setShippingFee = new EventEmitter();

  constructor(private orderDialogService: OrderDialogService) {}

  ngOnInit(): void {
    this.formControlChanges();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  formControlChanges() {
    this.productVariantControl.valueChanges
      .pipe(
        takeUntil(this._destroy$),
        tap((productVariantId) => {
          const order = this.orderView.data;

          if (!order) return;
          // if item is in cart update quantity, otherwise set quantity to 1
          const hasItem = order.items.find(({ variantId }) => variantId === productVariantId);
          const quantity = hasItem ? hasItem.quantity + 1 : 1;

          // emit the update
          this.updateOrderItem.emit({
            productVariantId,
            quantity,
          });

          // reset the control
          this.productVariantControl.patchValue(null, { emitEvent: false });
        }),
      )
      .subscribe();
  }

  quantityUpdated(item: IOrderItemDetailObject, quantity: number): void {
    // emit the update
    this.updateOrderItem.emit({
      productVariantId: item.variantId,
      quantity,
    });
  }

  adjustItem(orderItem: IOrderItemDetailObject) {
    const order = this.orderView.data;
    if (!order) {
      console.warn();
      return;
    }

    // prompt for adjustment amount and label
    this.orderDialogService
      .openAdjustmentDialog({ currency: order.currency })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          // emit the update
          this.adjustOrderItem.emit({
            productVariantId: orderItem.variantId,
            ...result,
          });
        }
      });
  }

  removeItem(orderItem: IOrderItemDetailObject) {
    // emit the update
    this.removeOrderItem.emit({
      productVariantId: orderItem.variantId,
      source: orderItem.source,
    });
  }

  variantProductHidden(item: IOrderDetailProductVariantItemObject) {
    return item.product.hidden;
  }

  variantUnavailableForPurchase(item: IOrderDetailProductVariantItemObject) {
    return !(item.channel?.available ?? true);
  }

  variantUnavailableInShippingAddressGeoRegion(item: IOrderDetailProductVariantItemObject) {
    if (!item.channel) return true; // channel not configured

    if (item.channel.availableRegions.length === 0) return false; // available in all regions

    const shippingAddressGeoRegionId = this.orderView.data?.shippingAddressGeoRegion?.id;
    if (!shippingAddressGeoRegionId) return false;

    return !item.channel.availableRegions.some((r) => r.id === shippingAddressGeoRegionId);
  }
}
