<h2 mat-dialog-title>{{ data?.templateId ? 'Update' : 'Create' }} Order Template</h2>
<form
  mat-dialog-content
  [formGroup]="orderTemplateForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="order_template_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
      </mat-form-field>
      <mat-form-field>
        <mat-label>Channel</mat-label>
        <x-data-select
          [provider]="Providers.ChannelItemCollectionProvider"
          formControlName="channelId"
        ></x-data-select>
      </mat-form-field>
      <x-locale-select [byChannelId]="channelId" formControlName="locale"></x-locale-select>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="closeDialog()">Cancel</button>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    form="order_template_form"
    [disabled]="orderTemplateForm.invalid"
  >
    {{ data?.templateId ? 'Update' : 'Create' }}
  </button>
</div>
