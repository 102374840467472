@if (this.view.items.length > 0) {
  <div class="trigger" matTooltip="View my pending order incidents" (click)="openApprovalDialog()">
    <fa-icon icon="circle-exclamation-check"></fa-icon>
    <span class="count">
      {{ this.view.items.length }}
    </span>
  </div>
}

<ng-template #approvalDialogTemplate>
  <h2 mat-dialog-title>
    <fa-icon icon="circle-exclamation-check"></fa-icon>
    Pending order incidents assigned to me
  </h2>
  <x-order-incident-table
    mat-dialog-content
    [style.height]="'100%'"
    [view]="view"
  ></x-order-incident-table>
</ng-template>
