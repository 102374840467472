import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { GoogleMapsModule } from '@angular/google-maps';
import { StorageModule } from '../storage';
import { MapComponent } from './components/map.component';
import { MAP_MODULE_CONFIG, MapModuleConfig } from './map-module.config';

const COMPONENTS = [MapComponent];

@NgModule({
  imports: [CommonModule, StorageModule, GoogleMapsModule],
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
})
export class MapModule {
  static forRoot(config: MapModuleConfig): ModuleWithProviders<MapModule> {
    return {
      ngModule: MapModule,
      providers: [
        {
          provide: MAP_MODULE_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
