import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FulfilmentIntervalService, IFulfilmentIntervalItem } from '@x/ecommerce/domain-client';

export interface OrderFulfilmentIntervalDialogData {
  orderId: number;
  shippingMethodId?: number | null;
}

export interface OrderFulfilmentIntervalDialogResult {
  interval: IFulfilmentIntervalItem | null;
}

@Component({
  selector: 'x-order-fulfilment-interval-dialog',
  templateUrl: 'order-fulfilment-interval-dialog.component.html',
})
export class OrderFulfilmentIntervalDialogComponent {
  control = new UntypedFormControl(null);
  detailControl = new UntypedFormControl(false);

  intervals$ = this.fulfilmentIntervalService.fetchItems(
    this.data.orderId,
    this.data.shippingMethodId,
  );
  trackById = (i: number, o: any) => o.id;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: OrderFulfilmentIntervalDialogData,
    private dialogRef: MatDialogRef<
      OrderFulfilmentIntervalDialogComponent,
      OrderFulfilmentIntervalDialogResult
    >,
    private fulfilmentIntervalService: FulfilmentIntervalService,
  ) {}

  submit() {
    this.dialogRef.close({ interval: this.control.value ?? null });
  }
}
