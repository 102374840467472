import { Injectable } from '@angular/core';
import { OrderService } from '@x/ecommerce/domain-client';
import { VariableContextService } from '../../auth/services/variable-context.service';

export interface ICartsContextVariable {
  currentCartId: number;
  carts: ICartContext[];
}

export interface ICartContext {
  id: number;
}

const ADMIN_CARTS_VARIABLE_KEY = 'ecommerce_admin.carts';

@Injectable()
export class CartsContextService {
  state$ = this.variables.observeUserVariable(ADMIN_CARTS_VARIABLE_KEY);
  // orders$ =

  constructor(
    private readonly variables: VariableContextService,
    private readonly orderService: OrderService,
  ) {}

  addCart(id: number) {}

  refreshOrders() {
    this.orderService.fetchItems({
      filter: {
        ids: [],
      },
    });
  }
}
