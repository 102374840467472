import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { WindowRef } from '@x/common/browser';
import { DataView } from '@x/common/data';
import { PromptDialogService } from '@x/dashboard/dialog';
import { IOrderIncidentDetailObject, OrderIncidentService } from '@x/ecommerce/domain-client';
import { UserItemCollectionProvider } from '@x/ecommerce/domain-data';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class OrderIncidentActionsService {
  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly router: Router,
    private readonly window: WindowRef,
    private readonly incidentService: OrderIncidentService,
    private readonly prompt: PromptDialogService,
  ) {}

  publish(view: DataView<IOrderIncidentDetailObject, number>) {
    view
      .observeMutation((id) => this.incidentService.publish(id), {
        label: 'Publishing Order Incident',
      })
      .subscribe();
  }

  unpublish(view: DataView<IOrderIncidentDetailObject, number>) {
    view
      .observeMutation((id) => this.incidentService.unpublish(id), {
        label: 'Unpublishing Order Incident',
      })
      .subscribe();
  }

  deny(view: DataView<IOrderIncidentDetailObject, number>) {
    view
      .observeMutation((id) => this.incidentService.deny(id), {
        label: 'Denying Order Incident',
      })
      .subscribe();
  }

  async approve(view: DataView<IOrderIncidentDetailObject, number>) {
    return lastValueFrom(
      view.observeMutation((id) => this.incidentService.approve(id), {
        label: 'Approving Order Incident',
      }),
    );
  }

  async assignApprover(view: DataView<IOrderIncidentDetailObject, number>) {
    const result = await lastValueFrom(
      this.prompt
        .autocomplete({
          title: 'Assign Approver',
          message: 'Select a user to assign as approver for this incident',
          provider: UserItemCollectionProvider,
          filter: {
            permissions: ['order_incidents_approve'],
          },
        })
        .afterClosed(),
    );

    if (!result || !result.assign || !result.value) {
      return;
    }

    const approverUserId = Number(result.value);
    return lastValueFrom(
      view.observeMutation((id) =>
        this.incidentService.update({
          id,
          approverUserId,
        }),
      ),
    );
  }

  async unassignApprover(view: DataView<IOrderIncidentDetailObject, number>) {
    return lastValueFrom(
      view.observeMutation((id) =>
        this.incidentService.update({
          id,
          approverUserId: null,
        }),
      ),
    );
  }

  createReplacementOrder(view: DataView<IOrderIncidentDetailObject, number>) {
    view
      .observeMutation((id) => this.incidentService.createReplacementOrder(id), {
        label: 'Create Order Incident Replacement Order',
      })
      .subscribe();
  }

  removeReplacementOrder(view: DataView<IOrderIncidentDetailObject, number>) {
    view
      .observeMutation((id) => this.incidentService.removeReplacementOrder(id), {
        label: 'Remove Order Incident Replacement Order',
      })
      .subscribe();
  }
}
