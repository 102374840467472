<ng-container *ngIf="operation$ | async as operation">
  <h2 mat-dialog-title>Voucher Payment</h2>

  <div mat-dialog-content>
    <x-cube-spinner *ngIf="operation.status === 'loading'"></x-cube-spinner>
    <x-dashboard-form-layout *ngIf="operation.status !== 'loading'">
      <x-form-panel class="check-code">
        <mat-form-field floatLabel="always">
          <mat-label>Voucher Code</mat-label>
          <input matInput type="text" [formControl]="voucherCodeControl" required />
          <button
            type="button"
            mat-button
            matSuffix
            [disabled]="voucherCodeControl.invalid"
            (click)="checkBalance()"
          >
            Check Balance
          </button>
        </mat-form-field>
      </x-form-panel>
    </x-dashboard-form-layout>
  </div>

  <div mat-dialog-actions align="center" class="actions" *ngIf="voucherBalance">
    <span class="mat-body-1">
      Voucher Balance:
      {{ voucherBalance.amount | fromCents | currency : voucherBalance.currency }}
    </span>

    <button
      type="submit"
      mat-flat-button
      color="primary"
      (click)="pay()"
      [disabled]="!(voucherBalance.amount > 0)"
    >
      Pay
      {{
        (voucherBalance && data.payment.amount > voucherBalance.amount
          ? voucherBalance.amount
          : data.payment.amount
        )
          | fromCents
          | currency : data.payment.order.currency
      }}
    </button>
  </div>
</ng-container>
