import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  AnyOperationState,
  OperationErrorState,
  OperationInitialState,
  OperationObserverService,
  OperationSuccessState,
} from '@x/common/operation';
import { IPaymentDetailObject, PaymentService } from '@x/ecommerce/domain-client';
import { VoucherPaymentBalanceObject } from '@x/schemas/ecommerce';
import { BehaviorSubject, map, Observable, of, startWith, switchMap, tap } from 'rxjs';

export interface IcarePaymentDialogData {
  payment: IPaymentDetailObject;
}

export type IcarePaymentDialogResult = {};

@Component({
  selector: 'x-icare-payment-dialog',
  templateUrl: './icare-payment-dialog.component.html',
  styleUrls: ['./icare-payment-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IcarePaymentDialogComponent {
  voucherCodeControl = new FormControl<string | null>(null, [Validators.required]);
  amountControl = new FormControl<number | null>(null);

  voucherCode$ = new BehaviorSubject<string | null>(null);
  operation$: Observable<AnyOperationState> = of(new OperationInitialState());

  voucherBalance: VoucherPaymentBalanceObject | undefined;

  constructor(
    public dialog: MatDialogRef<IcarePaymentDialogComponent, IcarePaymentDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: IcarePaymentDialogData,
    private readonly paymentService: PaymentService,
    private readonly operationObserver: OperationObserverService,
    private readonly snackbar: MatSnackBar,
  ) {}

  checkBalance() {
    this.operation$ = this.voucherCode$.pipe(
      switchMap((code) =>
        code
          ? this.operationObserver
              .observe(
                this.paymentService.checkVoucherPaymentBalance({
                  code,
                  paymentId: this.data.payment.id,
                }),
              )
              .pipe(
                tap((operation) => {
                  if (operation instanceof OperationSuccessState) {
                    this.voucherBalance = operation.data;
                  }
                  if (operation instanceof OperationErrorState) {
                    this.snackbar.open(operation.error.message, 'OK');
                  }
                }),
                map((operation) => operation.state),
              )
          : of(new OperationInitialState()),
      ),
      startWith(new OperationInitialState()),
    );

    this.voucherCode$.next(this.voucherCodeControl.value);
  }

  async pay() {
    if (!this.voucherCode$.value) {
      return;
    }

    this.operation$ = this.voucherCode$.pipe(
      switchMap((code) =>
        code
          ? this.operationObserver
              .observe(
                this.paymentService.redeemVoucherPayment({
                  code,
                  paymentId: this.data.payment.id,
                }),
              )
              .pipe(map((operation) => operation.state))
          : of(new OperationInitialState()),
      ),
      startWith(new OperationInitialState()),
      tap((operation) => {
        if (operation instanceof OperationSuccessState) {
          this.dialog.close();
        }
        if (operation instanceof OperationErrorState) {
          this.snackbar.open(operation.error.message, 'OK');
        }
      }),
    );
  }
}
