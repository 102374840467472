import { InjectionToken } from '@angular/core';

export const MAP_MODULE_CONFIG = new InjectionToken('MAP_MODULE_CONFIG');

export type MapModuleConfig = {
  defaultCenter?: {
    lat: number;
    lng: number;
  };
  defaultZoom?: number;
};
