<h2 mat-dialog-title>{{ 'orderPaymentDialog.title' | l }}</h2>

<mat-dialog-content *ngIf="!polling$.value && !loading$.value">
  <p *ngIf="order$ | async as order">
    Outstanding amount: {{ order.outstandingTotal | fromCents | currency : order.currency }}
  </p>
  <mat-accordion class="m-3">
    <mat-expansion-panel
      hideToggle
      *ngFor="let method of paymentMethods$ | async; let i = index"
      (closed)="selectedMethodIndex = -1"
      (afterExpand)="selectedMethodIndex = i"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ method.method.name }}
          <span style="margin-left: auto">
            <fa-icon [icon]="['far', 'circle']" *ngIf="selectedMethodIndex !== i"></fa-icon>
            <fa-icon
              [icon]="['far', 'dot-circle']"
              *ngIf="selectedMethodIndex === i"
              xColor="primary"
            ></fa-icon>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list dense>
        <mat-list-item *ngFor="let userMethod of method.userMethods">
          {{ userMethod.description }}
          <button
            mat-flat-button
            class="ms-auto"
            [matTooltip]="'orderPaymentDialog.action.pay' | l"
            (click)="requestPayment(userMethod)"
            [disabled]="!userMethod.reusable && !userMethod.expired"
          >
            <fa-icon icon="credit-card"></fa-icon>
          </button>
        </mat-list-item>
      </mat-list>
      <div class="d-flex justify-center mt-2">
        <mat-slide-toggle *ngIf="method.method.canReuse" [(ngModel)]="save">Save</mat-slide-toggle>
        <button
          mat-flat-button
          class="ms-auto"
          color="primary"
          (click)="requestNewPayment(method.method)"
        >
          New Payment
        </button>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      hideToggle
      *ngIf="wallets$ | async as wallets"
      (closed)="selectedMethodIndex = -1"
      (afterExpand)="selectedMethodIndex = paymentMethods$.value.length"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          User Wallet
          <span style="margin-left: auto">
            <fa-icon
              [icon]="['far', 'circle']"
              *ngIf="selectedMethodIndex !== paymentMethods$.value.length"
            ></fa-icon>
            <fa-icon
              [icon]="['far', 'dot-circle']"
              *ngIf="selectedMethodIndex === paymentMethods$.value.length"
              xColor="primary"
            ></fa-icon>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-list dense>
        <mat-list-item *ngFor="let wallet of wallets">
          Balance {{ wallet.balance | money : wallet.currency }}
          <button
            mat-flat-button
            class="ms-auto"
            [matTooltip]="'orderPaymentDialog.action.pay' | l"
            (click)="requestWalletPayment(wallet)"
            [disabled]="wallet.balance === 0"
          >
            <fa-icon icon="wallet"></fa-icon>
          </button>
        </mat-list-item>
      </mat-list>
    </mat-expansion-panel>
  </mat-accordion>
</mat-dialog-content>

<mat-dialog-content *ngIf="polling$.value || loading$.value">
  <x-cube-spinner></x-cube-spinner>
</mat-dialog-content>

<mat-dialog-actions align="start">
  <button mat-flat-button (click)="closeDialog()">Close</button>
</mat-dialog-actions>
