<h2 mat-dialog-title>Payment Refund</h2>
<form mat-dialog-content [formGroup]="formGroup">
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Refund Note</mat-label>
        <textarea matInput formControlName="note" rows="3"></textarea>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Refund Amount</mat-label>
        <x-currency-input-control
          [currency]="currency"
          formControlName="amount"
        ></x-currency-input-control>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-flat-button color="warn" (click)="submit()">
    <fa-icon icon="hand-holding-dollar"></fa-icon>
    &nbsp;Refund
  </button>
</div>
