import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { WindowRef } from '@x/common/browser';
import { DataView } from '@x/common/data';
import { Operation } from '@x/common/operation';
import { PromptDialogService } from '@x/dashboard/dialog';
import {
  CartService,
  IOrderDetailObject,
  IPaymentDetailObject,
  OrderService,
  PaymentService,
  WalletService,
} from '@x/ecommerce/domain-client';
import { AddressAssignment, OrderItemSource, OrderState } from '@x/schemas/ecommerce';
import { firstValueFrom, of, switchMap } from 'rxjs';
import { AddressDialogService } from '../../core/services/address-dialog.service';
import { ShipmentFormDialogData } from '../../logistics/components/shipment-form-dialog/shipment-form-dialog.component';
import { ShipmentDialogService } from '../../logistics/services/shipment-dialog.service';
import { PaymentsDialogService } from '../../payments/services/payment-dialog.service';
import { PaymentProviderDialogService } from '../../payments/services/payment-provider-dialog.service';
import { WalletDialogService } from '../../wallets/services/wallet-dialog.service';
import {
  IAdjustOrderItemEvent,
  IRemoveOrderItemEvent,
  IUpdateOrderItemEvent,
} from '../components/order-item-manager/order-item-manager.component';
import { OrderDialogService } from './order-dialog.service';

@Injectable()
export class OrderActionsService {
  constructor(
    private readonly snackbar: MatSnackBar,
    private readonly router: Router,
    private readonly window: WindowRef,
    private readonly orderService: OrderService,
    private readonly cartService: CartService,
    private readonly paymentService: PaymentService,
    private readonly walletService: WalletService,
    private readonly paymentDialogs: PaymentsDialogService,
    private readonly addressDialogService: AddressDialogService,
    private readonly orderDialogService: OrderDialogService,
    private readonly paymentProviderDialogService: PaymentProviderDialogService,
    private readonly walletDialogService: WalletDialogService,
    private readonly shipmentDialogService: ShipmentDialogService,
    private readonly prompt: PromptDialogService,
  ) {}

  // order
  async process(view: DataView<IOrderDetailObject, number>) {
    if (!(await firstValueFrom(this.orderDialogService.openProcessConfirmation()))) return;
    view
      .observeMutation((id) => this.cartService.process(id))
      .subscribe((operation) => this.handleOperationResult(operation, 'Order processed'));
  }
  createEnquiry(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.cartService.enquire({ orderId: Number(id) }))
      .subscribe((operation) => this.handleOperationResult(operation, 'Created enquiry'));
  }
  cancel(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) =>
        this.prompt
          .prompt({
            title: 'Cancel order',
            message: 'Are you sure you want to cancel this order?',
            actions: [
              { name: 'No', result: false },
              { name: 'Cancel Order', result: true, color: 'warn' },
            ],
          })
          .pipe(switchMap((confirm) => (confirm ? this.orderService.cancel(id) : of(null)))),
      )
      .subscribe((operation) =>
        this.handleOperationResult(operation, 'Order cancelled successfully'),
      );
  }
  expire(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) =>
        this.prompt
          .prompt({
            title: 'Expire order',
            message:
              'You are about to expire this order. Would you like to send a notification to the customer?',
            actions: [
              { name: 'Cancel', result: null },
              { name: 'Expire Only', result: false, color: 'warn' },
              { name: 'Notify Customer', result: true, color: 'warn' },
            ],
          })
          .pipe(
            switchMap((notifyUser) => {
              if (notifyUser === null) return of(null);
              return this.orderService.expire(id, notifyUser);
            }),
          ),
      )
      .subscribe((operation) =>
        this.handleOperationResult(operation, 'Order expired successfully'),
      );
  }
  recreate(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) =>
        this.prompt
          .prompt({
            title: 'Recreate order',
            message: 'Are you sure you want to recreate this order?',
            actions: [
              { name: 'Cancel', result: false },
              { name: 'Recreate', result: true, color: 'primary' },
            ],
          })
          .pipe(switchMap((confirm) => (confirm ? this.cartService.recreate(id) : of(null)))),
      )
      .subscribe((operation) => {
        this.handleOperationResult(operation, 'Order recreated successfully');
        if (operation.data?.id) {
          this.router.navigate(['admin/orders', operation.data.id, 'detail']);
        }
      });
  }

  // order items
  updateOrderItem(view: DataView<IOrderDetailObject, number>, input: IUpdateOrderItemEvent): void {
    view
      .observeMutation((id) => this.cartService.updateItem({ ...input, orderId: Number(id) }), {
        label: 'Updating Order Item',
      })
      .subscribe((operation) => this.handleOperationResult(operation));
  }
  adjustOrderItem(view: DataView<IOrderDetailObject, number>, event: IAdjustOrderItemEvent) {
    view
      .observeMutation(
        (orderId) =>
          this.orderService.adjustItem({
            orderId,
            productVariantId: event.productVariantId,
            amount: event.amount,
            label: event.label,
          }),
        { label: 'Adjusting order item' },
      )
      .subscribe((operation) => this.handleOperationResult(operation));
  }
  removeOrderItem(view: DataView<IOrderDetailObject, number>, item: IRemoveOrderItemEvent) {
    if (item.source === OrderItemSource.Shipping) {
      view
        .observeMutation(
          (orderId) =>
            this.orderService.setShipmentFixedCost({
              orderId,
              cost: null,
            }),
          { label: 'Removing Shipping Fee' },
        )
        .subscribe((operation) => this.handleOperationResult(operation));
      return;
    }

    view
      .observeMutation(
        (id) =>
          this.cartService.removeItem({
            productVariantId: item.productVariantId,
            orderId: Number(id),
          }),
        { label: 'Removing Order Item' },
      )
      .subscribe((operation) => this.handleOperationResult(operation));
  }
  async openShippingFeeDialog(view: DataView<IOrderDetailObject, number>) {
    if (!view.data) return;

    const result = await firstValueFrom(
      this.prompt
        .enterMoney({
          currency: view.data.currency,
          okLabel: 'Set',
          title: 'Set Shipping Fee',
          min: 0,
          required: false,
        })
        .afterClosed(),
    );

    if (result?.assign) {
      view
        .observeMutation(
          (orderId) =>
            this.orderService.setShipmentFixedCost({
              orderId,
              cost: result.value,
            }),
          { label: 'Setting shipping fee' },
        )
        .subscribe();
    }
  }

  // customer
  assignCustomer(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openAssignCustomerDialog(view.data?.user?.id)
      .afterClosed()
      .subscribe((res) => {
        if (res?.assign) {
          view
            .observeMutation(
              (orderId) =>
                this.orderService.assignOrderCustomer({ orderId, userId: Number(res.value) }),
              { label: 'Assigning Customer' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }
  removeCustomer(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation(
        (orderId) =>
          this.orderService.assignOrderCustomer({ orderId: Number(orderId), userId: null }),
        { label: 'Removing Customer' },
      )
      .subscribe((operation) => this.handleOperationResult(operation));
  }

  // shipping address
  async updateShippingAddress(view: DataView<IOrderDetailObject, number>) {
    this.addressDialogService
      .openAddressInputDialog({
        title: 'Edit Order Address',
        value: view.data?.shippingAddress,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;

        view
          .observeMutation(
            (id) =>
              this.cartService.assignAddress({
                assignment: AddressAssignment.Shipping,
                orderId: Number(id),
                address: result.value,
              }),
            { label: 'Updating Address' },
          )
          .subscribe((operation) => this.handleOperationResult(operation));
      });
  }
  assignCustomerAddress(view: DataView<IOrderDetailObject, number>) {
    if (!view.data?.user?.id) return;

    this.orderDialogService
      .openAssignCustomerAddressDialog(view.data.user.id, view.data.shippingAddress?.id)
      .afterClosed()
      .subscribe((r) => {
        if (r?.assign && r.value) {
          view
            .observeMutation(
              (orderId) =>
                this.cartService.assignCloneAddress({
                  orderId: orderId,
                  addressId: Number(r.value),
                  assignment: AddressAssignment.Shipping,
                }),
              { label: 'Assigning Customer Address' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }
  assignCollectionPoint(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openAssignCollectionPointDialog()
      .afterClosed()
      .subscribe((r) => {
        if (r && r.value && typeof r.value === 'number') {
          const val = r.value;
          view
            .observeMutation(
              (orderId) =>
                this.orderService.updateCollectionPoint({ collectionPointId: val, orderId }),
              { label: 'Assigning Collection Point' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }

  // coupon / referrer
  assignCouponCode(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openAssignCouponCodeDialog(view.data?.couponCode)
      .afterClosed()
      .subscribe((res) => {
        if (res?.assign) {
          view
            .observeMutation((orderId) =>
              this.cartService.assignCouponCode({
                orderId: Number(orderId),
                couponCode: res.value,
              }),
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }
  assignReferrer(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openAssignReferrerCodeDialog(view.data?.referrerCode)
      .afterClosed()
      .subscribe((res) => {
        if (res?.assign) {
          view
            .observeMutation((orderId) =>
              this.cartService.assignReferrerCode({
                orderId: Number(orderId),
                referrerCode: res.value,
              }),
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }

  // gift options
  updateGiftOptions(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openGiftOptionsDialog({
        isGift: view.data?.isGift ?? false,
        giftMessage: view.data?.giftMessage ?? null,
      })
      .afterClosed()
      .subscribe((input) => {
        if (input) {
          view
            .observeMutation(
              (orderId) => this.orderService.updateOrderGiftOptions({ ...input, orderId }),
              { label: 'Updating Gift Options' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }

  // packing instructions
  updatePackingInstructions(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openInstructionsDialog({
        instructions: view.data?.instructions,
      })
      .afterClosed()
      .subscribe((input) => {
        if (input) {
          view
            .observeMutation(
              (orderId) => this.orderService.updateOrderInstructions({ ...input, orderId }),
              { label: 'Updating Packing Instructions' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }

  // fulfilment
  cancelFulfilment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.cancelFulfilment(id), {
        label: 'Cancelling Fulfilment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Fulfilment cancelled'));
  }
  unallocateFulfilment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.unallocateFulfilment(id), {
        label: 'Unallocating Fulfilment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Fulfilment unallocated'));
  }
  allocateFulfilment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.allocateFulfilment(id), {
        label: 'Allocating Fulfilment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Fulfilment unallocated'));
  }
  packFulfilment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.packFulfilment(id), {
        label: 'Packing Fulfilment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Fulfilment packed'));
  }
  unpackFulfilment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.unpackFulfilment(id), {
        label: 'Unpack Fulfilment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Fulfilment unpacked'));
  }

  // shipment
  async assignShippingSlot(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openFulfilmentIntervalDialog({ orderId: Number(view.id) })
      .afterClosed()
      .subscribe((result) => {
        if (result?.interval?.slotAvailability?.slotId) {
          const slotId = result.interval.slotAvailability.slotId;

          view
            .observeMutation(
              (id) =>
                this.orderService.requestOrderShipmentSlot({
                  orderId: id,
                  slotId,
                }),
              { label: 'Assigning Shipping Slot' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }
  cancelShipment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.cancelShipment(id), {
        label: 'Cancelling Shipment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment cancelled'));
  }
  reconShipment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.reconShipment(id), {
        label: 'Reconciling Shipment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment reconciled'));
  }
  deliverShipment(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.deliverShipment(id), {
        label: 'Delivering Shipment',
      })
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment delivered'));
  }
  submitShipmentWaybill(view: DataView<IOrderDetailObject, number>) {
    view
      .observeMutation((id) => this.orderService.submitShipmentWaybill(id), {
        label: 'Submitting Shipment Waybill',
      })
      .subscribe((operation) =>
        this.handleOperationResult(operation, 'Shipment waybill submitted'),
      );
  }
  shipShipment(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService.openOrderShipConfirmation().subscribe((result) => {
      if (!result) return;
      view
        .observeMutation((id) => this.orderService.shipShipment(id, result.sendNotification), {
          label: 'Shipping Shipment',
        })
        .subscribe((operation) => this.handleOperationResult(operation, 'Shipment shipped'));
    });
  }
  async rescheduleShipment(view: DataView<IOrderDetailObject, number>) {
    if (!view.data?.id) return;

    const data: ShipmentFormDialogData = {
      methodId: view.data?.shipment?.method?.id,
      orderId: view.data.id,
      isRequested: true,
      slotId: view.data?.shipment?.slot?.id,
    };

    const result = await firstValueFrom(
      this.shipmentDialogService.openShipmentFormDialog(data).afterClosed(),
    );

    if (!result) return;

    view
      .observeMutation((orderId) => {
        return this.orderService.rescheduleOrderShipment({
          orderId,
          methodId: result.methodId,
          slotId: result.slotId,
        });
      })
      .subscribe();
  }

  // payments
  openPaymentDialog(payment: IPaymentDetailObject) {
    this.paymentProviderDialogService.open(payment).afterClosed().subscribe();
  }
  refundPayment(view: DataView<IOrderDetailObject, number>, payment: IPaymentDetailObject) {
    this.paymentDialogs
      .openPaymentRefundDialog({
        currency: payment.currency,
        amount: payment.amount,
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          view
            .observeMutation(
              () =>
                this.paymentService.refund({
                  paymentId: payment.id,
                  amount: result.amount,
                  note: result.note,
                }),
              {
                label: 'Refunding Payment',
              },
            )
            .subscribe((operation) => this.handleOperationResult(operation, 'Payment refunded'));
        }
      });
  }
  refundToWallet(view: DataView<IOrderDetailObject, number>) {
    if (!view.data) return;

    this.walletDialogService
      .openOrderRefundToWalletDialog({
        currency: view.data.currency,
        amount: null,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;

        view
          .observeMutation(
            (orderId) =>
              this.walletService.refundOrderToWallet({
                description: result.description,
                orderId,
                amount: result.amount ?? null,
              }),
            { label: 'Refunding Payment to Wallet' },
          )
          .subscribe((operation) => this.handleOperationResult(operation, 'Refunded to wallet'));
      });
  }
  async manualPayment(view: DataView<IOrderDetailObject, number>) {
    const cart = view.data;

    if (!cart) return;

    if (cart.state !== 'QUOTED') {
      await this.checkout(view);
    }

    const result = await firstValueFrom(
      this.paymentDialogs.openManualPaymentDialog({ currency: cart.currency }).afterClosed(),
    );

    if (result) {
      view
        .observeMutation(
          (orderId) =>
            this.paymentService.createManualPayment({
              orderId,
              ...result,
            }),
          { label: 'Creating Manual Payment' },
        )
        .subscribe((operation) => this.handleOperationResult(operation, 'Manual payment created'));
    }
  }
  async checkout(view: DataView<IOrderDetailObject, number>) {
    const cart = view.data;

    if (!cart) return;

    if (!cart.checkoutRequirements.canCheckout) {
      this.orderDialogService.openCheckoutRequirementsWarning(cart.checkoutRequirements);
      return;
    }

    if (!(await firstValueFrom(this.orderDialogService.openCheckoutConfirmation()))) return;

    view
      .observeMutation((id) => this.cartService.checkout({ orderId: Number(id) }), {
        label: 'Order Checkout',
      })
      .subscribe((operation) => this.handleOperationResult(operation));
  }
  async pay(view: DataView<IOrderDetailObject, number>) {
    const cart = view.data;

    if (!cart) return;

    if (cart.state !== OrderState.Quoted) {
      await this.checkout(view);
    }

    this.paymentDialogs
      .openOrderPaymentDialog(cart.id)
      .afterClosed()
      .subscribe(() => {
        view.refresh();
      });
  }

  // adjustments
  adjustOrder(view: DataView<IOrderDetailObject, number>) {
    this.orderDialogService
      .openAdjustmentDialog({ currency: view.data?.currency ?? 'ZAR' })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          view
            .observeMutation(
              (orderId) =>
                this.cartService.assignAdjustment({
                  orderId,
                  ...result,
                }),
              { label: 'Making order adjustment' },
            )
            .subscribe((operation) => this.handleOperationResult(operation));
        }
      });
  }

  // misc
  openInNewTab(url: string) {
    this.window.openNewTab(url);
  }
  private handleOperationResult(operation: Operation, successMessage?: string) {
    if (operation.isSuccessState() && successMessage) {
      this.snackbar.open(successMessage);
    }
    if (operation.isErrorState()) {
      this.snackbar.open(operation.state.error.message);
    }
  }
}
