<google-map #mapControl [options]="mapOptions" width="100%" height="100%">
  <map-marker-clusterer [imagePath]="IMAGE_PATH" [options]="clusterOptions">
    <ng-container *ngFor="let item of markers">
      <map-marker
        #marker="mapMarker"
        (mapClick)="markerClick(item, marker, infoWindow)"
        (mapDragend)="markerDragEnd($event, item, marker)"
        [options]="item.marker"
      ></map-marker>
      <map-info-window #infoWindow="mapInfoWindow" [options]="infoWindowOptions">
        <ng-container
          *ngTemplateOutlet="infoWindowTemplate; context: { $implicit: item.data }"
        ></ng-container>
      </map-info-window>
    </ng-container>

    <ng-container *ngFor="let item of polygons">
      <map-polygon
        #polygon="mapPolygon"
        (polygonClick)="polygonClick(item, polygon)"
        (polygonDragend)="polygonDragEnd($event, item, polygon)"
        [options]="item.polygon"
      ></map-polygon>
    </ng-container>
  </map-marker-clusterer>
</google-map>
