<h2 mat-dialog-title>Create Order</h2>
<form
  mat-dialog-content
  [formGroup]="createOrderForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="create_order_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field>
        <mat-label>Channel</mat-label>
        <x-data-select
          [provider]="Providers.ChannelItemCollectionProvider"
          formControlName="channelId"
        ></x-data-select>
      </mat-form-field>
      <x-locale-select [byChannelId]="channelId" formControlName="locale"></x-locale-select>
      <mat-checkbox formControlName="skipAgreements" [checked]="true">Skip Agreements</mat-checkbox>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-button
    type="submit"
    color="primary"
    form="create_order_form"
    [disabled]="createOrderForm.invalid"
  >
    Create
  </button>
</div>
