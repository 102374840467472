import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DataTableView } from '@x/common/data';
import { DataRowReorderEvent } from '@x/dashboard/grid/data-table/data-table.component';
import { OrderIncidentCategoryFilterInput } from '@x/schemas/ecommerce';
import { IOrderIncidentCategoryItemObject } from 'libs/ecommerce/src/domain-client/services/types/order-incident';

@Component({
  selector: 'x-order-incident-category-table',
  templateUrl: 'order-incident-category-table.component.html',
  styleUrls: ['order-incident-category-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-order-incident-category-table',
  },
})
export class OrderIncidentCategoryTableComponent implements OnInit {
  @Input()
  view: DataTableView<
    IOrderIncidentCategoryItemObject,
    OrderIncidentCategoryFilterInput,
    any,
    number
  >;

  @Input() selectable = true;
  @Input() activatable = true;
  @Input() reorderableRows = false;
  @Output() rowReorder = new EventEmitter<DataRowReorderEvent<IOrderIncidentCategoryItemObject>>();

  constructor(private changeRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.view.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  reorder(event: DataRowReorderEvent<IOrderIncidentCategoryItemObject>) {
    this.rowReorder.emit(event);
  }
}
