<iframe
  *ngIf="frameSrc; else noReference"
  id="peachCardFrame"
  name="peachCardFrame"
  frameborder="0"
  [src]="frameSrc"
  target="peach-card-frame"
  accept-charset="utf-8"
  mat-dialog-content
></iframe>

<ng-template #noReference>
  <p mat-dialog-content>Payment reference not provided</p>
</ng-template>
