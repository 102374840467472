import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface OrderInstructionsDialogData {
  instructions?: string | null;
}

export interface OrderInstructionsDialogResult {
  instructions?: string | null;
}

@Component({
  selector: 'x-order-gift-options-dialog',
  template: `
    <h2 mat-dialog-title>Update Order Instructions</h2>
    <mat-dialog-content class="mat-typography" [formGroup]="formGroup">
      <x-dashboard-form-layout>
        <mat-form-field appearance="outline">
          <mat-label>Order Instructions</mat-label>
          <textarea
            matInput
            formControlName="instructions"
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="20"
          ></textarea>
        </mat-form-field>
      </x-dashboard-form-layout>
    </mat-dialog-content>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="submit()">Update</button>
    </div>
  `,
})
export class OrderInstructionsDialogComponent {
  formGroup = new UntypedFormGroup({
    instructions: new UntypedFormControl(this.data.instructions ?? null, [
      Validators.maxLength(2048),
    ]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: OrderInstructionsDialogData,
    private dialogRef: MatDialogRef<
      OrderInstructionsDialogComponent,
      OrderInstructionsDialogResult
    >,
  ) {}

  submit() {
    if (this.formGroup.invalid) return;

    const value = this.formGroup.value;

    let instructions = value.instructions ? String(value.instructions).trim() : null;
    if (typeof instructions === 'string' && instructions.length === 0) instructions = null;

    this.dialogRef.close({ instructions });
  }
}
