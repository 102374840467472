import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DataTableView } from '@x/common/data';
import { AuthContextService } from '@x/ecommerce-admin/app/auth/services/auth-context.service';
import { OrderIncidentService } from '@x/ecommerce/domain-client';
import { OrderIncidentFilterInput } from '@x/schemas/ecommerce';
import { IOrderIncidentItemObject } from 'libs/ecommerce/src/domain-client/services/types/order-incident';

@Component({
  selector: 'x-order-incident-table',
  templateUrl: 'order-incident-table.component.html',
  styleUrls: ['order-incident-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-order-incident-table',
  },
})
export class OrderIncidentTableComponent implements OnInit {
  @Input()
  view: DataTableView<IOrderIncidentItemObject, OrderIncidentFilterInput, any, number>;

  @Input() selectable = true;
  @Input() activatable = true;

  constructor(
    private changeRef: ChangeDetectorRef,
    private readonly authContext: AuthContextService,
    private readonly incidentService: OrderIncidentService,
  ) {}

  ngOnInit(): void {
    this.view.stateChanges().subscribe(() => this.changeRef.markForCheck());
  }

  canPublish(row: any) {
    return (
      this.authContext.currentUserHasPermissions(['order_incidents_publish']) &&
      ['DRAFT'].includes(row.approvalState)
    );
  }
  publish(rowId: number) {
    this.view
      .mutateRow(rowId, (id) => this.incidentService.publish(id), {
        label: 'Publishing Order Incident',
      })
      .subscribe();
  }

  canUnpublish(row: any) {
    return (
      this.authContext.currentUserHasPermissions(['order_incidents_publish']) &&
      ['PENDING'].includes(row.approvalState)
    );
  }
  unpublish(rowId: number) {
    this.view
      .mutateRow(rowId, (id) => this.incidentService.unpublish(id), {
        label: 'Unpublishing Order Incident',
      })
      .subscribe();
  }

  canDeny(row: any) {
    return (
      this.authContext.currentUserHasPermissions(['order_incidents_approve']) &&
      ['PENDING'].includes(row.approvalState)
    );
  }
  deny(rowId: number) {
    this.view
      .mutateRow(rowId, (id) => this.incidentService.deny(id), {
        label: 'Denying Order Incident',
      })
      .subscribe();
  }

  canApprove(row: any) {
    return (
      this.authContext.currentUserHasPermissions(['order_incidents_approve']) &&
      ['PENDING'].includes(row.approvalState)
    );
  }
  approve(rowId: number) {
    this.view
      .mutateRow(rowId, (id) => this.incidentService.approve(id), {
        label: 'Approving Order Incident',
      })
      .subscribe();
  }
}
