import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataViewFactoryService } from '@x/common/data';
import { OrderDetailProvider } from '@x/ecommerce/domain-data';

export interface OrderPaymentDialogData {
  orderId: number;
}

@Component({
  selector: 'x-order-payment-dialog-2',
  templateUrl: 'order-payment-dialog.component.html',
  styleUrls: ['order-payment-dialog.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class OrderPaymentDialog2Component implements OnInit {
  orderView = this.dataViewFactory.resolveView(OrderDetailProvider, { id: this.data.orderId });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: OrderPaymentDialogData,
    private dialogRef: MatDialogRef<OrderPaymentDialog2Component>,
    private dataViewFactory: DataViewFactoryService,
  ) {}

  ngOnInit(): void {
    this.orderView.connect();
  }
}
