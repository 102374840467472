<button
  (click)="minus()"
  mat-icon-button
  [disabled]="!canMinus || disabled"
  color="primary"
  matTooltip="Decrease quantity"
>
  <fa-icon icon="minus-circle" size="lg"></fa-icon>
</button>

<input
  type="number"
  [min]="min"
  [max]="max"
  matInput
  name="itemQuantity"
  autocomplete="off"
  autocorrect="off"
  [formControl]="quantityControl"
  class="quantity"
/>

<button
  (click)="plus()"
  mat-icon-button
  [disabled]="!canPlus || disabled"
  color="primary"
  matTooltip="Increase quantity"
>
  <fa-icon icon="plus-circle" size="lg"></fa-icon>
</button>
