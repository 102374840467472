import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataCollectionView, DataFilterControl } from '@x/common/data';
import {
  ChannelItemCollectionProvider,
  CollectionPointItemCollectionProvider,
  GeoRegionItemCollectionProvider,
  OrderSourceItemCollectionProvider,
  OrderTagItemCollectionProvider,
  PaymentMethodItemCollectionProvider,
  ProductVariantItemCollectionProvider,
  PromotionItemCollectionProvider,
  ShippingCategoryItemCollectionProvider,
  ShippingMethodItemCollectionProvider,
  StockItemItemCollectionProvider,
  TaxonItemCollectionProvider,
  UserItemCollectionProvider,
  WarehouseItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { OrderFilterInput } from '@x/schemas/ecommerce';

@Component({
  selector: 'x-order-filters',
  templateUrl: './order-filters.component.html',
  styleUrls: ['./order-filters.component.scss'],
})
export class OrderFiltersComponent {
  readonly Providers = {
    PromotionItemCollectionProvider,
    TaxonItemCollectionProvider,
    UserItemCollectionProvider,
    ProductVariantItemCollectionProvider,
    OrderTagItemCollectionProvider,
    ShippingMethodItemCollectionProvider,
    GeoRegionItemCollectionProvider,
    WarehouseItemCollectionProvider,
    ChannelItemCollectionProvider,
    ShippingCategoryItemCollectionProvider,
    StockItemItemCollectionProvider,
    PaymentMethodItemCollectionProvider,
    OrderSourceItemCollectionProvider,
    CollectionPointItemCollectionProvider,
  };

  @Input() set view(view: DataCollectionView) {
    this.filterGroup = new DataFilterControl(
      {
        archived: new FormControl(),
        channelIds: new FormControl(),
        checkoutAt: new FormControl(),
        relativeCheckoutAt: new FormControl(),
        shippingSlotAt: new FormControl(),
        relativeShippingSlotAt: new FormControl(),
        fulfilmentStates: new FormControl(),
        isGift: new FormControl(),
        paymentMethodIds: new FormControl(),
        paymentStates: new FormControl(),
        shipmentStates: new FormControl(),
        shippingAddressGeoRegionIds: new FormControl(),
        shippingMethodIds: new FormControl(),
        shippingCategories: new FormGroup({
          ids: new FormControl(),
          exclude: new FormControl(false),
        }),
        tagIds: new FormControl(),
        states: new FormControl(),
        userIds: new FormControl(),
        warehouseIds: new FormControl(),
        productVariantIds: new FormControl(),
        promotionIds: new FormControl(),
        taxon: new FormGroup({
          ids: new FormControl(),
          exclude: new FormControl(false),
        }),
        authorId: new FormControl(),
        orderSources: new FormControl(),
        stockItemIds: new FormControl<number[] | null>(null),
        collectionPointIds: new FormControl<number[] | null>(null),
      },
      {
        modelViewTransform: (filter: OrderFilterInput) => ({
          ...filter,
          taxon: {
            ids: filter?.taxon?.ids ?? null,
            exclude: filter?.taxon?.exclude ?? false,
          },
          shippingCategories: {
            ids: filter?.shippingCategories?.ids ?? null,
            exclude: filter?.shippingCategories?.exclude ?? false,
          },
        }),
      },
    );

    view.setFilterControl(this.filterGroup);
  }

  filterGroup: DataFilterControl<any>;
}
