<h2 mat-dialog-title>Edit Parcel</h2>
<form
  mat-dialog-content
  [formGroup]="parcelForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="create_order_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field>
        <mat-label>Width</mat-label>
        <input matInput type="number" formControlName="width" />
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Height</mat-label>
        <input matInput type="number" formControlName="height" />
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Length</mat-label>
        <input matInput type="number" formControlName="length" />
        <span matSuffix>mm</span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" formControlName="weight" />
        <span matSuffix>g</span>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="warn" (click)="remove()">Remove</button>
  <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-button
    type="submit"
    color="primary"
    form="create_order_form"
    [disabled]="parcelForm.invalid"
  >
    Save
  </button>
</div>
