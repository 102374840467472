<h2 mat-dialog-title>Recreate Order</h2>
<div mat-dialog-content>Are you sure you want to recreate this order?</div>
<form
  mat-dialog-content
  [formGroup]="recreateOrderForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="recreate_order_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-checkbox formControlName="skipAgreements" [checked]="true">Skip Agreements</mat-checkbox>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-button
    type="submit"
    color="primary"
    form="recreate_order_form"
    [disabled]="recreateOrderForm.invalid"
  >
    Create
  </button>
</div>
